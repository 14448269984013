import {
  SET_PURCHASE_FLOW_IS_FACIAL,
  SET_PAYMENT_DETAILS,
} from '../../lib/constants';
export const initialPurchaseState = {
  purchaseFlowIsFacial: false,
  paymentDetails: {
    paymentAlbumId: 0,
    paymentAmount: 0,
    paymentAlbum: {},
    paymentImage: {},
    paymentImageId: 0,
    paymentType: 'album',
    facialPayment: false,
  },
};

export function purchaseReducer(state = initialPurchaseState, action) {
  switch (action.type) {
    case SET_PURCHASE_FLOW_IS_FACIAL: {
      return {
        ...state,
        purchaseFlowIsFacial: action.payload,
      };
    }
    case SET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentAlbumId: action.payload.albumId,
          paymentAmount: action.payload.payAmount,
          paymentAlbum: action.payload.album,
          paymentImage: action.payload.image,
          paymentImageId: action.payload.imageId,
          paymentType: action.payload.paymentType,
          // facialPayment: action.payload.facialPayment,
          facialPayment: state.purchaseFlowIsFacial,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
