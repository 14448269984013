import {createAction} from 'redux-actions';

import {
  ACCESS_TOKEN,
  SET_CART_MODAL_OPEN,
  SET_LAST_LOCATION,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_TRY_LOGIN,
  SET_USER_DETAILS,
  SET_MENU_HEIGHT,
  SET_OPEN_ORDERS,
  SET_SEASON_PASS_IMAGES,
} from '../lib/constants';

import {
  login,
  register,
  updateUserApi,
  requestResetApi,
  resetPasswordApi,
  getUserDetailsApi,
  removeUserTeamSubscriptionApi,
  removePaymentSourceApi,
  setOrderPoolApi,
  getOpenAlbumsApi, getAlbumUUID,
  loginPasswordless,
  findUserAthletes,
  findAllUserAthleteImages,
} from '../api';
import {getUserAlbums} from './albums/albumActions';
import * as Sentry from '@sentry/react';
import {crisp} from '../../src/index';

// crisp.configure('b0fb5e74-a56f-401b-82a3-17f3aa6c9392');

const setLastLocation = createAction(SET_LAST_LOCATION);
const setLogin = createAction(SET_LOGGED_IN);
const setTryLogin = createAction(SET_TRY_LOGIN);
export const setLoggedOut = createAction(SET_LOGGED_OUT);
const setUserDetails = createAction(SET_USER_DETAILS);
const setOpenOrders = createAction(SET_OPEN_ORDERS);
export const setCartModalOpen = createAction(SET_CART_MODAL_OPEN);
export const setMenuHeight = createAction(SET_MENU_HEIGHT);
const setSeasonPassImages = createAction(SET_SEASON_PASS_IMAGES);

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

export function tryLogin(dispatch, state) {
  dispatch(setTryLogin(state));
}

export const loginUser = (loginDetails) => {
  return async (dispatch) => {
    const response = await login(loginDetails.identifier, loginDetails.password);
    // if (response.status === 429) {
    //   throw new Error(response.data.message.messages[0].message);
    // }
    return finishLogin(response.data, dispatch);
  };
};

export const loginUserPasswordless = (token) => {
  return async (dispatch) => {
    // try {
    const response = await loginPasswordless(token);
    // if (response.status !== 200) {
    //   throw new Error(response.data.message);
    // }
    return finishLogin(response.data, dispatch);
    // } catch (err) {
    //   throw new Error(err);
    //   console.log(err);
    // }
  };
};

export const finishLogin = async (response, dispatch) => {
  if (response && response.jwt && response.jwt.length > 0 && response.user) {
    const jwt = response.jwt;
    // const refreshToken = response.refreshToken
    const user = response.user;
    localStorage.setItem(ACCESS_TOKEN, jwt);
    // localStorage.setItem(REFRESH_TOKEN, refreshToken)
    localStorage.setItem('USER', JSON.stringify(user));
    try {
      // Set Sentry User
      Sentry.setUser({email: user.email});
    } catch (error) {
      console.error('error setting Sentry User: ', error);
    }

    if (dispatch) {
      const userInfo = parseJwt(jwt);

      const loginInfo = {
        user: user,
        loggedIn: true,
        tryLogin: false,
      };
      dispatch(setLogin(loginInfo));
    }
  } else {
    throw new Error('Login Failed, please try again');
  }
};

export const logoutUser = () => {
  return async (dispatch) => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('USER');
    dispatch(setLoggedOut());
    try {
      // Clear Sentry User
      Sentry.setUser(null);
    } catch (error) {
      console.error('error clearing Sentry User: ', error);
    }
  };
};

export const getLoginStorage = (location, history) => {
  return async (dispatch) => {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    const user = JSON.parse(localStorage.getItem('USER'));
    if ((jwt ?? '').length > 0) {
      const parsedJwt = parseJwt(jwt);
      const loginInfo = {
        user: user,
        loggedIn: true,
        tryLogin: false,
      };
      try {
      // Set Sentry User
        Sentry.setUser({email: user.email});
      } catch (error) {
        console.error('error setting Sentry User: ', error);
      }
      dispatch(setLogin(loginInfo));
    } else {
      dispatch(setLastLocation(location.pathname));
    }
  };
};

export const registerUser = (registrationDetails) => {
  return async (dispatch) => {
    const regResponse = await register(registrationDetails.email, registrationDetails.password, registrationDetails.firstName, registrationDetails.lastName, registrationDetails.birthDate);
    if (regResponse.status === 200) {
      const response = await login(registrationDetails.email, registrationDetails.password);
      // if (response.status !== 200) {
      //   throw new Error('Login Failed, please try again');
      //   // throw new Error("User could not be created at this time, please try again")
      // }
      return [finishLogin(response.data, dispatch), regResponse.data.user];
    };
    return [finishLogin(regResponse.data, dispatch), regResponse.data.user];
  };
};

export const updateUser = async (dispatch, userDetails) => {
  const userUpdateResponse = await updateUserApi(userDetails.id, userDetails.firstName, userDetails.lastName, userDetails.country, userDetails.city, userDetails.state, userDetails.zipCode, userDetails.birthDate, userDetails.agreedToTerms);
  if (userUpdateResponse.status !== 200) {
    throw new Error('User could not be updated, please try again');
  } else {
    if (dispatch) {
      dispatch(setUserDetails({user: userUpdateResponse.data}));
    }
    return userUpdateResponse.data;
  }
};

let lastGetUserDetails = new Date(0);

export const getUserDetails = async (dispatch) => {
  // console.log('last call', lastGetUserDetails.getTime() <= new Date().getTime() - 10000);
  if (lastGetUserDetails.getTime() <= new Date().getTime() - 1000) {
    lastGetUserDetails = new Date();
    try {
      const userDetailsRes = await getUserDetailsApi();
      try {
        crisp.user.setEmail(userDetailsRes.data.email);
        crisp.user.setNickname(userDetailsRes.data.name);
        crisp.user.setPhone(userDetailsRes.data.phone_number);
      } catch (err) {
        console.error(err);
      }
      try {
        console.log('userDetails', userDetailsRes.data);
        crisp.session.setData({
          user_id: userDetailsRes.data.id});
        crisp.session.setData({payment_sources: JSON.stringify(userDetailsRes.data.payment_sources)});
        crisp.session.setData({team_subscriptions: JSON.stringify(userDetailsRes.data.team_subscriptions)});
      } catch (err) {
        console.error(err);
      }
      if (dispatch) {
        dispatch(setUserDetails({user: userDetailsRes.data}));
      }
    } catch (err) {
    // Store the current
      if (dispatch) {
        logoutUser(dispatch);
      } else {
        logoutUser();
      }
    }
  }
};

const findFreshPass = function(athleteId, ownedSeasonPasses) {
  if (!ownedSeasonPasses) {
    return null;
  };
  for (const seasonPass of ownedSeasonPasses) {
    if (seasonPass.user_athlete.id === athleteId && new Date() <= new Date(seasonPass.expires_on) && seasonPass.paid) {
      return seasonPass;
    }
  }
  return null;
};

const findFreshWeekendPass = function(athleteId, inputDate, ownedWeekendPasses) {
  if (!ownedWeekendPasses) {
    return null;
  };
  for (const weekendPass of ownedWeekendPasses) {
    if (weekendPass.user_athlete.id === athleteId && (new Date(inputDate) <= new Date(weekendPass.expires_on)) && weekendPass.paid && (new Date(inputDate) >= new Date(weekendPass.starts_on))) {
      return weekendPass;
    }
  }

  return null;
};

export const getSeasonPassImages = async (dispatch) => {
  try {
    const userDetailsRes = await getUserDetailsApi();
    const userAthletesRes = await findUserAthletes(userDetailsRes.data.id);
    const allUserAthleteImages = await Promise.all(userAthletesRes.map(async (userAthlete) => {
      const images = [];
      const pass = findFreshPass(userAthlete.id, userDetailsRes.data.season_passes);
      const userAthleteImages = await findAllUserAthleteImages(userAthlete.id);
      for (const weekend of userAthleteImages.weekends) {
        for (const album of weekend.albums) {
          for (const image of album.images) {
            if (pass) {
              if ((new Date(pass.starts_on) <= new Date(image.created_at)) && (new Date(image.created_at) <= new Date(pass.expires_on))) {
                images.push(image.id);
              }
            } else {
              const weekendPass = findFreshWeekendPass(userAthlete.id, new Date(image.created_at), userDetailsRes.data.weekend_passes);
              if (weekendPass) {
                if ((new Date(weekendPass.starts_on) <= new Date(image.created_at)) && (new Date(image.created_at) <= new Date(weekendPass.expires_on))) {
                  images.push(image.id);
                }
              }
            }
          }
        }
      }
      return images;
    }));

    const flatImagesArray = allUserAthleteImages.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);
    if (dispatch) {
      dispatch(setSeasonPassImages(flatImagesArray));
    }
  } catch (err) {
    // Store the current
    console.log(err);
    if (dispatch) {
      logoutUser(dispatch);
    } else {
      logoutUser();
    }
  }
};

let lastGetUserAlbums = new Date();
export const doSetAlbums = async (dispatch) => {
  if (lastGetUserAlbums.getTime() <= new Date().getTime() - 1000) {
    lastGetUserAlbums = new Date();
    getUserAlbums(dispatch);
  }
};

export const removeUserTeamSubscription = async (dispatch, id) => {
  try {
    const response = await removeUserTeamSubscriptionApi(id);
    if (dispatch) {
      dispatch(getUserDetails);
      dispatch(doSetAlbums);
    }
  } catch (err) {
    // Should bubble these messages somewhere....
  }
};

export const removePaymentSource = async (dispatch, id) => {
  try {
    const response = await removePaymentSourceApi(id);
    if (dispatch) {
      dispatch(getUserDetails);
    }
  } catch (err) {
    // Should bubble these messages somewhere....
  }
};

export const requestReset = async (fields) => {
  const request = await requestResetApi(fields.email);
  return request;
};

export const resetPassword = async (fields) => {
  const request = await resetPasswordApi(fields);
  return request;
};

export const testPasswordResetCode = async (code) => {
  const request = {};
  return 200;
};

export const getOpenAlbums = async (dispatch) => {
  const openAlbums = await getOpenAlbumsApi();
  const albums = openAlbums.data.length ? openAlbums.data : [];
  dispatch(setOpenOrders(albums));
};

export const setOrderPool = async (orderId, isPool) => {
  await setOrderPoolApi(orderId, isPool);
};

export const getAlbumByUUID = async (uuid) => {
  return await getAlbumUUID(uuid);
};
