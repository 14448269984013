import React, {
  useState,
  useMemo,
  useEffect,
  Suspense,
} from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {format} from 'date-fns';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import AddIcon from '@mui/icons-material/Add';
import {Pagination} from '@mui/material';
import {TextField} from '@mui/material';

import {Link} from 'react-router-dom';
import {getUserAlbums} from '../../store/albums/albumActions';
import {selectCurrentAlbumsState} from '../../store/albums/albumSelector';
import {selectCartItems} from '../../store/userSelectors';
import {useSelector, useDispatch} from 'react-redux';
const UserTeamSubscriptions = React.lazy(()=> import('./teamSubscriptions'));
import {Alert} from '@mui/material';
import {addItemToCartApi} from '../../api';
import {doGetUserDetails} from '../user/teamSubscriptions';
import {
  requiredSharesToReleaseOrder,
} from '../../lib/constants';
import {setCartModalOpen} from '../../store/userActions';
import {useHistory} from 'react-router';
import {makeMoney} from '../../lib/functions';
import {darkV4} from '../../theme';
export const doSetAlbums = async (dispatch) => {
  getUserAlbums(dispatch);
};

const UserAlbums = ({...rest}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userAlbums = useSelector((state) => selectCurrentAlbumsState(state));
  const cartItems = useSelector((state) => selectCartItems(state));

  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState('');
  const [alreadyInCartError, setAlreadyInCartError] = useState(false);
  const [alreadyInCartAlbumId, setAlreadyInCartAlbumid] = useState();
  const [sharesRemaining, setSharesRemaining] = useState(requiredSharesToReleaseOrder);
  const pageSize = 10;

  useEffect(() => {
    doSetAlbums(dispatch);
  }, [dispatch]);

  const filteredAlbums = useMemo(() => {
    return userAlbums.filter((album) => {
      const splitFilter = currentFilter
          .split(' ')
          .filter((event) => event.length > 0)
          .map((event) => event.toLowerCase());
      return splitFilter.reduce(
          (prev, curr) =>
            prev &&
          (album?.album?.program_name.toLowerCase().includes(curr) ||
            album?.album?.team_name.toLowerCase().includes(curr) ||
            album?.album?.division_name.toLowerCase().includes(curr) ||
            album?.event?.name.toLowerCase().includes(curr) ||
            album?.event?.brand_name.toLowerCase().includes(curr) ||
            album?.event?.city.toLowerCase().includes(curr) ||
            album?.event?.state.toLowerCase().includes(curr) ||
            album?.event?.producer_name.toLowerCase().includes(curr) ||
            album?.event?.venue_name.toLowerCase().includes(curr) ||
            String(album?.album?.performance_order)
                .toLowerCase()
                .includes(curr) ||
            false),
          true,
      );
    }).slice(
        currentPage !== 1 ? (currentPage - 1) * pageSize : 0,
        currentPage * pageSize,
    );
  }, [userAlbums, currentPage, currentFilter]);

  const handleFilterDivision = (e) => {
    setCurrentFilter(e.target.value);
  };

  const handleAddAlbumToCart = (albumId) => {
    const cartAlbumIds = [];
    cartItems.forEach((cartItem) => {
      cartAlbumIds.push(cartItem.album.id);
    });

    const addAlbumToCart = async () => {
      const response = await addItemToCartApi(albumId);
      doGetUserDetails(dispatch);
      dispatch(setCartModalOpen(true));
    };

    if (cartAlbumIds.includes(albumId)) {
      setAlreadyInCartError(true);
      setAlreadyInCartAlbumid(albumId);
    } else {
      addAlbumToCart();
    }
  };

  const getSharePrice = (album) => {
    return (album?.event?.event_pricings?.[0]?.share_price ?? 0) * 1 || 1995;
  };

  const albumRemainingShares = (album) => {
    /*
    const theNums = ['Zero','One','Two','Three','Four', 'Five','Six','Seven','Eight','Nine'];
    const numShares = album.album.album_payments.reduce((current, payment) => {
      const shares = Math.round(payment.total/getSharePrice(album))
      return Math.max(current - shares, 0)
    }, requiredSharesToReleaseOrder)
    if (numShares === 0) {
      return `Guaranteed purchase for ${makeMoney(getSharePrice(album), '$')}`
    } else if (numShares === 1) {
      return `One purchaser remaining to finalize purchase.`
    } else {
      return `Purchase for ${makeMoney(getSharePrice(album) * numShares, '$')}`
      //return `${theNums[numShares]} purchasers remaining to finalize purchase.`
    }
    */

    return `Purchase for ${makeMoney(getSharePrice(album) * 7, '$')}`;
  };


  return (
    <Container maxWidth="lg" style={{maxWidth: 'none'}}>
      <Grid
        container
        item
        xs={12}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Suspense fallback={<div>Loading Saved Team Searches...</div>}>
          <UserTeamSubscriptions />
        </Suspense>
        <Grid item xs={6} style={{minWidth: 330}}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={'Filter/Refine Results by Keywords'}
            id={`Search-Events`}
            value={currentFilter}
            name={'SearchEvents'}
            onChange={(e) => {
              handleFilterDivision(e);
            }}
          />
          {userAlbums.length > 0 && filteredAlbums.length > 0 ? (
              filteredAlbums
                  .map((album, i) => (
                    <Card
                      key={album.id}
                      style={{
                        backgroundColor: '#10579e',
                        margin: '10px',
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                        [{album.album.performance_order}] {' '}
                          {album.album.program_name} -{' '} {album.album.team_name} -{' '}
                          {album.album.division_name}
                        </Typography>
                        <Typography sx={{mb: 0.5}}>
                      Album Date: {format(new Date(album?.album?.created_at ?? new Date()), 'yyyy-MM-dd hh:mm a')}
                        </Typography>
                        <Typography sx={{mb: 0}}>
                        Event: {album.event?.brand_name} - {album.event?.name} [{album.event?.city}, {album.event?.state}]
                        </Typography>
                        <Typography sx={{mb: 0}}>
                          {album.event?.venue_name}
                        </Typography>
                        <Typography variant="caption" sx={{alignSelf: 'center'}}>
      REF#{album?.album?.id ?? 0}
                        </Typography>
                        <Typography sx={{mb: 0}}>
                        Views: {album.user_album_views.length}/{!album.owned && !album.bought_images ? '10' : '50'}
                        </Typography>
                        { !album.owned ?
                      <Typography><strong>{albumRemainingShares(album)}</strong></Typography> : <Typography><strong>Already Purchased</strong></Typography> }

                        {(!album.owned && !album.bought_images && album.user_album_views.length >= 10) || (!album.owned && album.bought_images && album.user_album_views.length >= 50) ?
                          <Alert severity="info">Album views exceeded. In an effort to protect athletes, we limit the number of times you are able to view an album before purchase ({album.bought_images ? 50 : 10}).</Alert> :
                        <></>
                        }
                      </CardContent>
                      { !album.owned ?
                        <Button
                          disabled={album.user_album_views.length === 0}
                          size="large"
                          onClick={() => {
                            history.push('/purchaseAlbum/' + album?.album?.uuid);
                          }}
                          sx={{color: darkV4.palette.secondary.contrastText}}
                          // variant="contained"
                          // color="secondary"
                        >
                          <AddIcon />
                          <Typography>{ album.user_album_views.length === 0 ? 'View Before Purchasing' : 'Purchase Album' }&nbsp;</Typography>
                        </Button> : <></>
                      }

                      <Button
                        component={Link}
                        to={`/user-album/${album.album.id}`}
                        size="large"
                        disabled={(!album.owned && !album.bought_images && album.user_album_views.length >= 10) || (!album.owned && album.bought_images && album.user_album_views.length >= 50)}
                        sx={{color: darkV4.palette.secondary.contrastText}}
                        // variant="contained"
                        // color="secondary"
                      >
                        <PhotoLibraryOutlinedIcon />
                          View Album
                      </Button>
                    </Card>
                  ))
            ) : (
              <Typography variant="h6" component="div">
                {currentFilter.length === 0 ?
                  'No albums available, please check back later.' :
                  'No albums found matching your search criteria.'}
              </Typography>
            )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          Displaying (
          {pageSize > filteredAlbums.length ? filteredAlbums.length : pageSize}{' '}
          of {userAlbums.length} albums)
          <Pagination
            style={{paddingTop: 20}}
            variant="outlined"
            shape="rounded"
            count={Math.ceil(userAlbums.length / pageSize)}
            page={currentPage}
            onChange={(e, p) => {
              setCurrentPage(p);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserAlbums;
