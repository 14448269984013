import {countryDateFormats} from './constants';
export const textGender = (genderId: number): string => {
  switch (genderId) {
    case 0: return 'male';
    case 1: return 'female';
    case 2: return 'other';
    case 3: return 'unknown';
  }
  return 'unknown';
};

export const addCommas = (nStr: string): string => {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

export const getCountryDateFormat = (date: Date): string => {
  const language = navigator.language;
  const format: string = (language in countryDateFormats) ? countryDateFormats[language] : 'MM/dd/yyyy';
  return format;
};

export const makeMoney = (amountInPennies: number, currency: string): string => {
  const value = addCommas((amountInPennies / 100).toFixed(2));
  return currency + value;
};

export const locationApiCall = async (longitude: number, latitude: number): Promise<any> => {
  const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
  return response.json();
};

export const displayMoney = (amountInPennies: number): string => {
  const symbol = '$';
  const value = addCommas((amountInPennies / 100).toFixed(2));
  return symbol + value;
};

export const checkIfAllTrue = (listOfBooleans: boolean[]): boolean => {
  return listOfBooleans.reduce((previousValue, currentValue) => {
    return previousValue && currentValue;
  });
};

export const checkIfOneButNotAllTrue = (listOfBooleans: boolean[]): boolean => {
  let allTrue = true;
  let oneTrue = false;
  listOfBooleans.forEach((value) => {
    if (value) oneTrue = true;
    if (!value) allTrue = false;
  });
  return oneTrue && !allTrue;
};

/**
 * Only return the fields named in the fields array
 *
 * @param {Object} data
 * @param {string[]} fields
 *
 * @return {Object}
 */
export const returnFilterFields = (data: any, fields: string[]): any => {
  const filteredData = {};
  fields.forEach((field) => {
    filteredData[field] = data[field];
  });
  return filteredData;
};

/**
 * Does this role have access to this page?
 *
 * @param {string} roleType Example: 'super', 'analyst', 'customer_support'
 * @param {string} page Example: 'customers', 'admin_albums', 'reports'
 * @return {boolean}
 */
export const checkRolePermission = (roleType: string, page: string): boolean => {
  // console.log('role:page ', roleType, page);
  const rolePermissions = {
    'super': ['all'],
    'analyst': ['admin', 'customers', 'customer_notification_settings', 'admin_albums', 'reports', 'profile', 'search_results', 'purchases', 'my_athlete', 'admin_photographer_report', 'admin_report_photo_review', 'admin_add_athlete_image'],
    'customer_support': ['admin', 'customers', 'customer_notification_settings', 'admin_albums', 'profile', 'search_results', 'purchases', 'my_athlete', 'admin_photographer_report', 'admin_report_photo_review', 'admin_add_athlete_image'],
    'authenticated': ['profile', 'search_results', 'purchases', 'my_athlete'],
    'public': [],
  };
  if (!roleType) return false;
  return rolePermissions[roleType].includes(page) || rolePermissions[roleType].includes('all');
};

export const formatMonthYear = (dateTimeStamp) => {
  const date = new Date(dateTimeStamp);
  const monthAbbreviations = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const month = monthAbbreviations[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
};

export const formatDateMonthDayYear = (dateTimeStamp) => {
  const date = new Date(dateTimeStamp);
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};
