import React, {
  useState,
  Suspense,
} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {useStyles} from '../../lib/theme';
import {getUserDetails, removeUserTeamSubscription} from '../../store/userActions';
import {selectTeamSubscriptions} from '../../store/userSelectors';
import {useSelector, useDispatch} from 'react-redux';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
const YesNoDialog = React.lazy(()=> import('../util/YesNoModal'));
const AddTeamSubscription = React.lazy(()=> import('./addTeamSubscription'));
import {Button} from '@mui/material';

export const doGetUserDetails = async (dispatch) => {
  getUserDetails(dispatch);
};

export const doRemoveUserTeamSubscription = async (dispatch, id) => {
  removeUserTeamSubscription(dispatch, id);
};

const UserTeamSubscriptions = ({...rest}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const teamSubscriptions = useSelector((state) => selectTeamSubscriptions(state));
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [deleteTeamQuestion, setDeleteTeamQuestion] = useState('');
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(0);
  const [addTeamSubscriptionDialogOpen, setAddTeamSubscriptionDialogOpen] = useState(false);


  const handleDelete = (team) => {
    setDeleteTeamQuestion(`Are you sure you would like to delete your subscription to albums for ${team.program_name} ${team.team_name} - ${team.division_name}?
        If you continue, you will no longer receive notifications to new albums for this team or see them here in your account.`);
    setCurrentSubscriptionId(team.id);
    setDelDialogOpen(true);
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="center" style={{paddingTop: 10}}>
        <Typography variant="h6">My Saved Team Searches</Typography>
      </Grid>

      <Grid container item xs={6} justifyContent="center">
        {teamSubscriptions.map((team, i) => (
          <Grid item key={team.id} xs="auto">
            <Chip size="small" label={`${team.program_name} ${team.team_name} - ${team.division_name}`} style={{backgroundColor: '#9c27b0', margin: 3}} onDelete={() => handleDelete(team)}/>
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={6} justifyContent="center" style={{paddingTop: 10}}>
        <Button variant="outlined" aria-label="delete" size="small" color="primary" onClick={()=> setAddTeamSubscriptionDialogOpen(!addTeamSubscriptionDialogOpen)}>
          <AddIcon></AddIcon> New Team/Event Search
        </Button>
      </Grid>
      <Suspense fallback={<div>Loading Prompt...</div>}>
        <YesNoDialog
          title={'Remove Team Subscription'}
          question={deleteTeamQuestion}
          isOpen={delDialogOpen}
          onClose={() => setDelDialogOpen(false)}
          buttonActions={[
            {name: 'Yes, I am Sure', color: 'secondary', callback: () => {
              doRemoveUserTeamSubscription(dispatch, currentSubscriptionId);
              setDelDialogOpen(false);
            },
            },
            {name: 'Cancel', color: 'primary', callback: () => setDelDialogOpen(false)},
          ]}
        />
      </Suspense>
      <Suspense fallback={<div>Loading Add Team Window...</div>}>
        <AddTeamSubscription canClose={true} isOpen={addTeamSubscriptionDialogOpen} onClose={()=> setAddTeamSubscriptionDialogOpen(false)} />
      </Suspense>
    </>
  );
};

export default UserTeamSubscriptions;
