import environment from './environment';
import axios, {AxiosRequestConfig} from 'axios';
import {ACCESS_TOKEN} from './lib/constants';
import {returnFilterFields} from './lib/functions';
import {useState, useEffect, DependencyList, useCallback} from 'react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {store} from '../src/index';
import * as Sentry from '@sentry/react';
import {setLoggedOut} from './store/userActions';
import {setError} from './store/errorAction';
import {crisp} from '../src/index';
import album from './components/user/album';

// crisp.configure('b0fb5e74-a56f-401b-82a3-17f3aa6c9392');

// This defaults to the backend server
axios.defaults.baseURL = environment.BASE_URL;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const {config, message} = error;
      let status = 503; // Default to "Service Unavailable"
      //   If the backend is down, we don't get a error.response back
      if (error?.response?.status) {
        status = error?.response?.status;
      }
      // DON'T CHANGE ANY OF THIS OR YOU WILL LIKELY BREAK THINGS....
      try {
        let isExpired = false;
        const token = localStorage.getItem(ACCESS_TOKEN);
        // console.log('token', token);
        if (token?.length ?? 0 > 0) {
          const decodedToken=jwt_decode(token, {complete: true});
          const dateNow = new Date();

          if (decodedToken.exp < dateNow.getTime()) {
            isExpired = true;
          }
        }
        if (status === UNAUTHORIZED && isExpired) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem('USER');
          store.dispatch(setLoggedOut());
          try {
            // Clear Sentry User
            Sentry.setUser(null);
          } catch (error) {
            console.error('error clearing Sentry User: ', error);
          }
          return Promise.reject(error);
        }
      } catch (err) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem('USER');
        store.dispatch(setLoggedOut());
        try {
          // Clear Sentry User
          Sentry.setUser(null);
        } catch (error) {
          console.error('error clearing Sentry User: ', error);
        }
        return Promise.reject(error);
      }
      if (!config || !config.retry) {
        return Promise.reject(error);
      }
      // retry while Network timeout or Network Error
      if (!(message.includes('timeout') || message.includes('Network Error') || status === 503 || status === 0)) {
        return Promise.reject(error);
      }

      store.dispatch(setError({statusCode: status, message}));
      config.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          console.log('retry the request', config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => axios(config));
    },
);


export const buildAxiosConfig = (sendWithJwt = false) => {
  if (sendWithJwt) {
    return {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      retry: 4, retryDelay: 1250,
    };
  } else {
    return {
      retry: 4, retryDelay: 1250,
    };
  }
};

export const buildAxiosConfigNoRetry = (sendWithJwt = false) => {
  if (sendWithJwt) {
    return {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    };
  } else {
    return {
    };
  }
};

export const getJwt = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const getTempJwt = () => {
  return localStorage.getItem('TEMP_ACCESS_TOKEN');
};
export const getUserId = () => {
  try {
    return JSON.parse(localStorage.getItem('USER'))?.id ?? 0;
  } catch (err) {
    return 0;
  }
};
export const getEvents = async () => {
  return await axios.get(`${environment.BASE_URL}/events?_sort=start_date:DESC&_limit=30&archived_ne=true`, buildAxiosConfig(false));
};

export const getAlbums = async (eventId) => {
  return await axios.get(`${environment.BASE_URL}/albums?events_in=${eventId}&_sort=performance_order:DESC`, buildAxiosConfig(false));
};

export const getEvent = async (eventId) => {
  return await axios.get(`${environment.BASE_URL}/events/${eventId}`, buildAxiosConfig(false));
};

// Define a cache object to store the results of the last call
const cache = {};

// Define your debounced async function
const debouncedGetImagesByAlbumId = async (albumId, resolve, reject) => {
  try {
    // If the result is already in the cache and not expired, return it
    if (cache[albumId] && cache[albumId].expiration > Date.now()) {
      resolve(cache[albumId].result);
      return;
    }
    // Push event to Crisp session
    crisp.session.pushEvent('view_album', {
      album_id: albumId,
      time: new Date().toISOString(),
    });
    // Make the API call
    const album = await axios.get(`${environment.BASE_URL}/albums/${albumId}`, buildAxiosConfig(true));
    localStorage.setItem('TEMP_ACCESS_TOKEN', album.data.temp_jwt);
    // Store the result in the cache with an expiration time of 10 minutes
    const expiration = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
    cache[albumId] = {result: album, expiration};
    // Return the result
    resolve(album);
  } catch (err) {
    // Reject the Promise if there's an error
    reject(err);
  }
};

// Define your original function that returns a Promise
export const getImagesByAlbumId = (albumId) => {
  return new Promise((resolve, reject) => {
    debouncedGetImagesByAlbumId(albumId, resolve, reject);
  });
};

export const getImagesByEventId = async (eventId) => {
  const event = await axios.get(`${environment.BASE_URL}/events/all-images/${eventId}`, buildAxiosConfig(true));
  // localStorage.setItem("TEMP_ACCESS_TOKEN", event.data.temp_jwt)
  return event;
};

export const login = async (email, password) => {
  try {
    crisp.user.setEmail(email);
  } catch (err) {
    console.error(err);
  }
  try {
    crisp.session.setData({
      'last_login': new Date().toISOString(),
    });
  } catch (err) {
    console.error(err);
  }
  return await axios.post(`${environment.BASE_URL}/auth/local`, {
    identifier: email,
    password,
  }, buildAxiosConfig(false));
};

export const loginPasswordless = async (loginToken) => {
  return await axios.get(`${environment.BASE_URL}/passwordless/login?loginToken=${loginToken}`);
};

export const register = async (email, password, firstName, lastName, birthDate) => {
  try {
    crisp.user.setEmail(email);
  } catch (err) {
    console.error(err);
  }
  try {
    crisp.session.setData({
      'last_login': new Date().toISOString(),
    });
    crisp.user.setNickname(`${firstName}, ${lastName}`);
  } catch (err) {
    console.error(err);
  }
  try {
    crisp.session.pushEvent('register_attempt', {
      'email': email,
      'first_name': firstName,
      'last_name': lastName,
      'birth_date': birthDate.toISOString(),
    });
  } catch (err) {
    console.error(err);
  }
  return await axios.post(`${environment.BASE_URL}/auth/local/register`, {
    email,
    username: email,
    password,
    first_name: firstName,
    last_name: lastName,
    name: `${firstName} ${lastName}`,
    birth_date: birthDate,
    agreed_to_terms: true,
    provider: 'local',
  }, buildAxiosConfig(false));
};

export const updateUserApi = async (id, firstName, lastName, country, city, state, zipCode, birthDate, agreedToTerms) => {
  const result = await axios.put(`${environment.BASE_URL}/users/${id}`, {
    first_name: firstName,
    last_name: lastName,
    name: `${firstName} ${lastName}`,
    country: country,
    city: city,
    state: state,
    zip_code: zipCode,
    birth_date: birthDate,
    agreed_to_terms: agreedToTerms,
  }, buildAxiosConfig(true));
  return result;
};

export const updateUserPhoneNumber = async (id, phoneNumber) => {
  const result = await axios.put(`${environment.BASE_URL}/users/${id}`, {
    phone_number: phoneNumber,
  }, buildAxiosConfig(true));
  return result.data;
};

export const requestResetApi = async (email) => {
  try {
    crisp.user.setEmail(email);
  } catch (err) {
    console.error(err);
  }
  return await axios.post(`${environment.BASE_URL}/auth/forgot-password`, {
    email,
  }, buildAxiosConfig(false));
};

export const resetPasswordApi = async (fields) => {
  return await axios.post(`${environment.BASE_URL}/auth/reset-password`, {
    code: fields.code,
    password: fields.password,
    passwordConfirmation: fields.password,
  }, buildAxiosConfig(false));
};

// Define a cache object to store the results of the last call
const userAlbumsCache = {};

// Define your debounced async function with caching
const debouncedGetUserAlbumsApi = async (resolve, reject) => {
  try {
    // If the result is already in the cache and not expired, return it
    if (userAlbumsCache.result && userAlbumsCache.expiration > Date.now()) {
      resolve(userAlbumsCache.result);
      return;
    }
    // Make the API call
    const result = await axios.get(`${environment.BASE_URL}/user-found-albums/me`, buildAxiosConfig(true));
    // Store the result in the cache with an expiration time of 10 minutes
    const expiration = Date.now() + 1000; // 10 minutes in milliseconds
    userAlbumsCache.result = result;
    userAlbumsCache.expiration = expiration;
    // Return the result
    resolve(result);
  } catch (err) {
    // Reject the Promise if there's an error
    reject(err);
  }
};

// Define your original function that returns a Promise, with caching
export const getUserAlbumsApi = () => {
  return new Promise((resolve, reject) => {
    debouncedGetUserAlbumsApi(resolve, reject);
  });
};

export const getOpenAlbumsApi = async () => {
  return await axios.get(`${environment.BASE_URL}/albums/open`, buildAxiosConfig(true));
};

// Define a cache object to store the results of the last call
const userDetailsCache = {};

// Define your debounced async function with caching
const debouncedGetUserDetailsApi = async (resolve, reject) => {
  try {
    // If the result is already in the cache and not expired, return it
    if (userDetailsCache.result && userDetailsCache.expiration > Date.now()) {
      resolve(userDetailsCache.result);
      return;
    }
    // Make the API call
    const result = await axios.get(`${environment.BASE_URL}/users/me`, buildAxiosConfig(true));
    // Store the result in the cache with an expiration time of 10 minutes
    const expiration = Date.now() + 1000; // 10 minutes in milliseconds
    userDetailsCache.result = result;
    userDetailsCache.expiration = expiration;
    // Return the result
    resolve(result);
  } catch (err) {
    // Reject the Promise if there's an error
    reject(err);
  }
};

// Define your original function that returns a Promise, with caching
export const getUserDetailsApi = () => {
  return new Promise((resolve, reject) => {
    debouncedGetUserDetailsApi(resolve, reject);
  });
};

export const removeUserTeamSubscriptionApi = async (id) => {
  return await axios.post(`${environment.BASE_URL}/team-subscriptions/remove-subscription`, {id: id}, buildAxiosConfig(true));
};

export const addFoundUserAlbumApi = async (id, event) => {
  return await axios.post(`${environment.BASE_URL}/user-found-albums`, {album: id, event}, buildAxiosConfig(true));
};

export const addUserTeamSubscriptionApi = async (fields) => {
  const {
    programName,
    teamName,
    divisionName,
  } = fields;
  return await axios.post(`${environment.BASE_URL}/team-subscriptions/add-subscription`, {
    program_name: programName,
    team_name: teamName,
    division_name: divisionName,
  }, buildAxiosConfig(true));
};

export const addItemToCartApi = async (albumId) => {
  return await axios.post(`${environment.BASE_URL}/carts/add-item`, {
    album: albumId,
  }, buildAxiosConfig(true));
};

export const removeCartItemApi = async (cartItemId) => {
  return await axios.post(`${environment.BASE_URL}/carts/remove-item`, {
    id: cartItemId,
  }, buildAxiosConfig(true));
};

export const incrementAlbumViewApi = async (albumId) => {
  return axios.get(`${environment.BASE_URL}/user-found-albums/${albumId}/view`, buildAxiosConfig(true));
};

export const addPaymentSource = async (token) => {
  return axios.post(`${environment.BASE_URL}/payment-sources/add-payment-method`, {token: token}, buildAxiosConfig(true));
};

export const removePaymentSourceApi = async (id) => {
  return axios.post(`${environment.BASE_URL}/payment-sources/remove-payment-method`, {id}, buildAxiosConfig(true));
};

export const getAdminAlbums = async () => {
  return axios.get(`${environment.BASE_URL}/albums/admin?_limit=37500&_sort=created_at:DESC`, buildAxiosConfig(true));
};

export const moveImageToAlbum = async (destAlbumId, imageId) => {
  return await axios.put(`${environment.BASE_URL}/images/${imageId}/album/${destAlbumId}`, {}, buildAxiosConfig(true));
};

export const moveImagesToAlbum = async (destAlbumId, imageIds) => {
  return await axios.post(`${environment.BASE_URL}/images/move-multiple`, {
    albumId: destAlbumId,
    images: imageIds,
  }, buildAxiosConfig(true));
};

export const addOrderPaymentApi = async (fields) => {
  const {
    orderId,
    paymentMethodStripeId,
    amount,
  } = fields;
  return axios.post(`${environment.BASE_URL}/order-payments/add-order-payment`, {
    orderId: orderId,
    paymentMethodId: paymentMethodStripeId,
    amount: amount,
  }, buildAxiosConfigNoRetry(true));
};

export const addAlbumPaymentApi = async (fields) => {
  const {
    paymentAlbumId,
    paymentMethodStripeId,
    amount,
    facial,
  } = fields;
  try {
    crisp.session.pushEvent('click_purchase_album', {
      album: paymentAlbumId,
      price: amount,
      from_facial: facial,
      stripe_id: paymentMethodStripeId,
    });
  } catch (err) {
    console.error(err);
  }
  return axios.post(`${environment.BASE_URL}/album-payments/add-album-payment`, {
    albumId: paymentAlbumId,
    paymentMethodId: paymentMethodStripeId,
    amount: amount,
    facial: facial,
  }, buildAxiosConfigNoRetry(true));
};

export const addImagePaymentApi = async (fields) => {
  const {
    paymentImageId,
    paymentMethodStripeId,
    amount,
    facial,
  } = fields;
  try {
    crisp.session.pushEvent('click_purchase_image', {
      image: paymentImageId,
      price: amount,
      from_facial: facial,
      stripe_id: paymentMethodStripeId,
    });
  } catch (err) {
    console.error(err);
  }
  return axios.post(`${environment.BASE_URL}/image-payments/add-image-payment`, {
    imageId: paymentImageId,
    paymentMethodId: paymentMethodStripeId,
    amount: amount,
    facial: facial,
  }, buildAxiosConfigNoRetry(true));
};

export const buySeasonPassApi = async (fields) => {
  const {
    total,
    userAthleteId,
    paymentMethodId,
    acknowledgements,
  } = fields;
  try {
    crisp.session.pushEvent('buy_season_pass', {
      total,
      userAthleteId,
    });
  } catch (err) {
    console.error(err);
  }
  return axios.post(`${environment.BASE_URL}/user-athletes/buy-season-pass`, {
    total,
    userAthleteId,
    paymentMethodId,
    acknowledgements,
  }, buildAxiosConfigNoRetry(true));
};

export const buyWeekendPassApi = async (fields) => {
  const {
    total,
    userAthleteId,
    paymentMethodId,
    startDate,
    endDate,
  } = fields;
  try {
    crisp.session.pushEvent('buy_weekend_pass', {
      total,
      userAthleteId,
    });
  } catch (err) {
    console.error(err);
  }
  return axios.post(`${environment.BASE_URL}/user-athletes/buy-weekend-pass`, {
    total,
    userAthleteId,
    paymentMethodId,
    startDate,
    endDate,
  }, buildAxiosConfigNoRetry(true));
};

export const getImageById = async (imageId) => {
  const result = axios.get(`${environment.BASE_URL}/images/preview/${imageId}`, buildAxiosConfig(true));
  return result.data;
};

export const getOrderInviteCount = async (orderId) => {
  const result = await axios.get(`${environment.BASE_URL}/order-invites/order/${orderId}`, buildAxiosConfig(true));
  return result.data;
};

export const getOrder = async (orderKey) => {
  const result = await axios.get(`${environment.BASE_URL}/orders/${orderKey}`, buildAxiosConfig(true));
  return result.data;
};

export const inviteEmail = async (orderId, email, albumUUID) => {
  const result = await axios.post(`${environment.BASE_URL}/order-invites`, {orderId: orderId, invited_email: email, order_key: albumUUID}, buildAxiosConfig(true));
  return result;
};

export const getAlbumInviteCount = async (albumId) => {
  const result = await axios.get(`${environment.BASE_URL}/album-invites/album/${albumId}`, buildAxiosConfig(true));
  return result.data;
};

export const inviteAlbumEmail = async (albumId, email, albumUUID) => {
  const result = await axios.post(`${environment.BASE_URL}/album-invites`, {albumId: albumId, email: email, albumUUID: albumUUID}, buildAxiosConfig(true));
  return result;
};

export const convertCartToOrder = async (cartId) => {
  const result = await axios.post(`${environment.BASE_URL}/carts/checkout`, {id: cartId}, buildAxiosConfig(true));
  return result;
};

export const setOrderPoolApi = async (orderId, isPool) => {
  return await axios.put(`${environment.BASE_URL}/orders/${orderId}`, {is_pool: isPool}, buildAxiosConfig(true));
};

export const downloadAlbumApi = async (albumId) => {
  try {
    crisp.session.pushEvent('download_album', {
      album: albumId,
      time: new Date().toISOString(),
    });
  } catch (err) {
    console.error(err);
  }
  const result = await axios.get(`${environment.BASE_URL}/user-album-downloads/download/${albumId}`, buildAxiosConfig(true));
  return result;
};

export const getAlbumUUID = async (albumUUID) => {
  const result = await axios.get(`${environment.BASE_URL}/albums/uuid/${albumUUID}`, buildAxiosConfig(true));
  return result.data;
};

export const getReport = async (startDate, endDate) => {
  const params = new URLSearchParams({
    start_date: new Date(startDate).toISOString(),
    end_date: new Date(endDate).toISOString(),
  });
  const result = await axios.get(`${environment.BASE_URL}/album-payments/report?${params.toString()}`, buildAxiosConfig(true));
  return result.data;
};

export const getImage = (imageId, fileName) => {
  const params = new URLSearchParams({
    auth: getJwt(),
  });
  try {
    crisp.session.pushEvent('download_image', {
      image: imageId,
      fileName: fileName,
      time: new Date().toISOString(),
    });
  } catch (err) {
    console.error(err);
  }
  return `${environment.BASE_URL}/images/${imageId}/${fileName}?${params.toString()}`;
};

export const createNewAlbum = async (eventId, teamName, programName, divisionName, performanceId, eventUuid) => {
  const result = await axios.post(`${environment.BASE_URL}/albums`, {
    team_name: teamName,
    program_name: programName,
    division_name: divisionName,
    performance_order: performanceId,
    events: [eventId],
    identifier: `${eventUuid}-${performanceId}`,
  }, buildAxiosConfig(true));
  return result.data;
};

export const getRecentUsers = async () => {
  const result = await axios.get(`${environment.BASE_URL}/users/customer-search`, buildAxiosConfig(true));
  return result.data;
};

export const searchUsersByName = async (name) => {
  const params = new URLSearchParams({
    name_contains: name,
  });
  const result = await axios.get(`${environment.BASE_URL}/users?${params.toString()}`, buildAxiosConfig(true));
  return result.data;
};

export const searchUsersByEmail = async (email) => {
  const params = new URLSearchParams({
    email_contains: email,
  });
  const result = await axios.get(`${environment.BASE_URL}/users?${params.toString()}`, buildAxiosConfig(true));
  return result.data;
};

export const getUserCount = async () => {
  const result = await axios.get(`${environment.BASE_URL}/users/count`, buildAxiosConfig(true));
  return result.data;
};

export const getUserById = async (id) => {
  const result = await axios.get(`${environment.BASE_URL}/users/${id}`, buildAxiosConfig(true));
  return result.data;
};

export const resetDownloadsById = async (id) => {
  const result = await axios.post(`${environment.BASE_URL}/user-album-downloads/reset/${id}`, {}, buildAxiosConfig(true));
  return result.data;
};

export const reprocessAlbumZip = async (userAlbumDownload) => {
  const result = await axios.post(`${environment.BASE_URL}/album-payments/reprocessAlbumZip`, userAlbumDownload, buildAxiosConfig(true));
  return result.data;
};

export const resetUserAlbumViews = async (id) => {
  const result = await axios.post(`${environment.BASE_URL}/user-found-albums/${id}/reset`, {}, buildAxiosConfig(true));
  return result.data;
};

export const swapImagePayment = async (id, imageId) => {
  const result = await axios.post(`${environment.BASE_URL}/image-payments/swap`, {
    image_payment_id: id,
    image_id: imageId,
  }, buildAxiosConfig(true));
  return result.data;
};

export const swapAlbumPayment = async (id, imageId) => {
  const result = await axios.post(`${environment.BASE_URL}/album-payments/swap`, {
    album_payment_id: id,
    album_id: imageId,
  }, buildAxiosConfig(true));
  return result.data;
};

export const smsTwilioVerificationTokenSend = async (phoneNumberE164) => {
  const result = await axios.post(`${environment.BASE_URL}/twilio-sms/verify-token-send`, {
    to: phoneNumberE164,
  }, buildAxiosConfig(true));
  return result.data;
};

export const smsTwilioVerificationTokenCheck = async (phoneNumberE164, token) => {
  const result = await axios.post(`${environment.BASE_URL}/twilio-sms/verify-token-check`, {
    to: phoneNumberE164,
    token: token,
  }, buildAxiosConfig(true));
  return result.data;
};

export const getPublicActionRoute = async (publicActionUUID, route) => {
  const result = await axios.post(`${environment.BASE_URL}/public-action-routes/find-public`, {
    action: publicActionUUID,
    client_route: route,
  }, buildAxiosConfig(false));
  return result.data;
};

export const getCurrentDefaultUserNotificationSetting = async (accessPublic=false) => {
  const queryParams = [
    {
      name: '_sort',
      value: 'id:DESC',
    },
    {
      name: '_limit',
      value: '1',
    },
  ];
  const queryParamsString = `?${queryParams.map((param) => `${param.name}=${param.value}`).join('&')}`;
  const config = buildAxiosConfig(!accessPublic);
  const result = await axios.get(`${environment.BASE_URL}/default-user-notification-settings${queryParamsString}`, config);
  return result.data[0];
};

export const updateDefaultUserNotificationSetting = async (data) => {
  const result = await axios.post(`${environment.BASE_URL}/default-user-notification-settings`, data, buildAxiosConfig(true));
  return result.data;
};

/**
 * get notification settings for the current user
 *
 * @return {Promise<object>}
//  * @return {Promise<[object,string]>} [data, statusText]
 */
export const getCurrentUserNotificationSetting = async () => {
  const config = buildAxiosConfig(true);
  const result = await axios.get(`${environment.BASE_URL}/users/me`, config);
  return result.data;
  // return [result.data, result.statusText];
};

/**
 * Get notification settings for a user (public or private)
 *
 * @param {number} userId
 * @param {boolean} [accessPublic]
 *
 * @return {Promise<object>}
//  * @return {Promise<[object,string]>} [data, statusText]
 */
export const getUserNotificationSetting = async (userId, accessPublic=false) => {
  const config = buildAxiosConfig(!accessPublic);
  const result = await axios.get(`${environment.BASE_URL}/users/get-settings/${userId}`, config);
  return result.data;
  return [result.data, result.statusText];
};

/**
 * Update notification settings for a user (public or private)
 *
 * @param {number} userId
 * @param {Object} data
 * @param {boolean} [accessPublic]
 *
 * @return {Promise<object>}
//  * @return {Promise<[object,string]>} [data, statusText]
 */
export const updateUserNotificationSetting = async (userId, data, accessPublic=false) => {
  // has_modified_notification_settings means not default settings for this user anymore
  data.has_modified_notification_settings = new Date().toISOString().slice(0, 10);
  const config = buildAxiosConfig(!accessPublic);
  const result = await axios.put(`${environment.BASE_URL}/users/update-settings/${userId}`, data, config);
  return result.data;
  // return [result.data, result.statusText];
};

/**
 * Find user athletes
 *
 * @param {number} userId
 *
 * @return {Promise<object>}
 */
export const findUserAthletes = async (userId) => {
  const params = new URLSearchParams({
    user_eq: userId,
    deleted_eq: false,
  });
  if (!userId) {
    return [];
  }
  const config = buildAxiosConfig(true);
  const result = await axios.get(`${environment.BASE_URL}/user-athletes/?${params.toString()}`, config);
  try {
    crisp.session.setData({'user_athletes': JSON.stringify(result.data)});
  } catch (err) {
    console.error(err);
  }
  return result.data;
};

/**
 * Find user athletes findAllUserAthleteImages
 *
 * @param {number} userId
 *
 * @return {Promise<object>}
 */
// Define a cache object to store the results of the last call
const userAthleteImagesCache = {};

// Define your debounced async function with caching
const debouncedFindAllUserAthleteImages = async (userId, resolve, reject) => {
  try {
    // Define a unique cache key for this function and userId
    const cacheKey = `findAllUserAthleteImages_${userId}`;
    // If the result is already in the cache and not expired, return it
    if (userAthleteImagesCache[cacheKey] && userAthleteImagesCache[cacheKey].expiration > Date.now()) {
      resolve(userAthleteImagesCache[cacheKey].result);
      return;
    }
    // Make the API call
    const params = new URLSearchParams({
      user_eq: userId,
      deleted_eq: false,
    });
    const config = buildAxiosConfig(true);
    const result = await axios.get(`${environment.BASE_URL}/user-athletes/findAllUserAthleteImages/${userId}/?${params.toString()}`, config);
    // Store the result in the cache with an expiration time of 10 minutes
    const expiration = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
    userAthleteImagesCache[cacheKey] = {result: result.data, expiration};
    // Return the result
    resolve(result.data);
  } catch (err) {
    // Reject the Promise if there's an error
    reject(err);
  }
};

// Define your original function that returns a Promise
export const findAllUserAthleteImages = (userId) => {
  return new Promise((resolve, reject) => {
    debouncedFindAllUserAthleteImages(userId, resolve, reject);
  });
};


/**
 * Create a user athlete
 *
 * @param {Object} data
 * @param {string} data.name
 * @param {number} data.user
 *
 * @return {Promise<object>}
 */
export const createUserAthlete = async (data) => {
  const config = buildAxiosConfig(true);
  const result = await axios.post(`${environment.BASE_URL}/user-athletes`, data, config);
  try {
    crisp.session.pushEvent('create_user_athlete', {
      athlete_id: result.data.id,
      name: data.name,
    });
  } catch (err) {
    console.error(err);
  }
  return result.data;
};

/**
 * Remove a user athlete
 *
 * @param {Object} data
 * @param {number} data.userAthleteId
 *
 * @return {Promise<object>}
 */
export const removeUserAthlete = async (data) => {
  const updateData = {deleted: true};
  const config = buildAxiosConfig(true);
  const result = await axios.delete(`${environment.BASE_URL}/user-athletes/${data.userAthleteId}`, config);
  try {
    crisp.session.pushEvent('remove_athlete', {
      athlete_id: data.userAthleteId,
    });
  } catch (err) {
    console.error(err);
  }
  return result.data;
};

/**
 * Create a user athlete reference photo
 *
 * @param {Object} data
 * @param {number} data.user_athlete
 * @param {object[]} data.reference_photos
 *
 * @return {Promise<object>}
 */
export const createUserAthleteReferencePhoto = async (data) => {
  const config = buildAxiosConfig(true);
  const result = await axios.post(`${environment.BASE_URL}/user-athlete-reference-photos`, data, config);
  try {
    crisp.session.pushEvent('add_athlete_photo', {
      athlete_id: data.user_athlete,
    });
  } catch (err) {
    console.error(err);
  }
  return result.data;
};

/**
 * Find one user athlete reference photo
 *
 * @param {number} userAthleteReferencePhotoId
 *
 * @return {Promise<object>}
 */
// Define a cache object to store the results of the last call
const userAthleteReferencePhotoCache = {};

// Define your debounced async function with caching
const debouncedFindOneUserAthleteReferencePhoto = async (userAthleteReferencePhotoId, resolve, reject) => {
  try {
    // Define a unique cache key for this function and userAthleteReferencePhotoId
    const cacheKey = `findOneUserAthleteReferencePhoto_${userAthleteReferencePhotoId}`;
    // If the result is already in the cache and not expired, return it
    if (userAthleteReferencePhotoCache[cacheKey] && userAthleteReferencePhotoCache[cacheKey].expiration > Date.now()) {
      resolve(userAthleteReferencePhotoCache[cacheKey].result);
      return;
    }
    // Make the API call
    const params = new URLSearchParams({
      deleted_eq: false,
    });
    const config = buildAxiosConfig(true);
    const result = await axios.get(`${environment.BASE_URL}/user-athlete-reference-photos/${userAthleteReferencePhotoId}/?${params.toString()}`, config);
    // Store the result in the cache with an expiration time of 10 minutes
    const expiration = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
    userAthleteReferencePhotoCache[cacheKey] = {result: result.data, expiration};
    // Return the result
    resolve(result.data);
  } catch (err) {
    // Reject the Promise if there's an error
    reject(err);
  }
};

// Define your original function that returns a Promise, with caching
export const findOneUserAthleteReferencePhoto = (userAthleteReferencePhotoId) => {
  return new Promise((resolve, reject) => {
    // Define a unique cache key for this function and userAthleteReferencePhotoId
    const cacheKey = `findOneUserAthleteReferencePhoto_${userAthleteReferencePhotoId}`;
    // If the result is already in the cache and not expired, return it
    if (userAthleteReferencePhotoCache[cacheKey] && userAthleteReferencePhotoCache[cacheKey].expiration > Date.now()) {
      resolve(userAthleteReferencePhotoCache[cacheKey].result);
      return;
    }
    // Otherwise, call the debounced function
    debouncedFindOneUserAthleteReferencePhoto(userAthleteReferencePhotoId, (result) => {
      // Store the result in the cache with an expiration time of 10 minutes
      const expiration = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
      userAthleteReferencePhotoCache[cacheKey] = {result, expiration};
      // Return the result
      resolve(result);
    }, reject);
  });
};

/**
 * Find Admin Photographers Report
 *
 * @return {Promise<object>}
 */
export const getPhotographersReport = async () => {
  const config = buildAxiosConfig(true);
  const result = await axios.get(`${environment.BASE_URL}/images/getPhotographersReport`, config);
  return result.data;
};

/**
 * get api status
 *
 * @return {Promise<object>}
 */
export const getStatus = async () => {
  const result = await axios.get(`https://rtevent.crisp.watch/includes/report/`, {timeout: 600});
  return result.data;
};

export const sendLoginLink = async (email) => {
  try {
    crisp.session.pushEvent('reset_password', {
      email,
    });
  } catch (err) {
    console.error(err);
  }
  try {
    crisp.user.setEmail(email);
  } catch (err) {
    console.error(err);
  }
  const result = await axios.post(`${environment.BASE_URL}/passwordless/send-link`, {email, username: email}, buildAxiosConfig(false));
  return result.data;
};

export const reportPhoto = async (imageId, albumId, eventId, type, memo) => {
  try {
    crisp.session.pushEvent('report_photo', {
      image_id: imageId,
      album_id: albumId,
      event_id: eventId,
      type,
      memo,
    });
  } catch (err) {
    console.error(err);
  }
  const result = await axios.post(`${environment.BASE_URL}/reported-photos`, {image: imageId, album: albumId, event: eventId, user: getUserId(), type, memo}, buildAxiosConfig(true));
  return result.data;
};

export const getReportPhotosForReview = async () => {
  const params = new URLSearchParams({
    ack_ne: true, // ack is false/null
    _sort: 'id:DESC',
  });
  const result = await axios.get(`${environment.BASE_URL}/reported-photos?${params.toString()}`, buildAxiosConfig(true));
  return result.data;
};

export const acknowledgeReportedPhoto = async (imageId, removed) => {
  // console.log('acknowledgeReportedPhoto', imageId, removed);
  const result = await axios.post(`${environment.BASE_URL}/reported-photos/ack`, {
    image: imageId,
    removed: removed,
    // response: 'So sorry, we\'ve removed it.',
  }, buildAxiosConfig(true));
  return result.data;
};

export const verifyEmail = async (email) => {
  const result = await axios.post(`${environment.BASE_URL}/auth/send-email-confirmation`, {email}, buildAxiosConfig(false));
  return result.data;
};

export const adminVerifyEmail = async (id) => {
  const result = await axios.put(`${environment.BASE_URL}/users/verify-user-email/${id}`, {}, buildAxiosConfig(true));
  return result.data;
};

export const getUserDownloadsList = async (userId) => {
  const result = await axios.get(`${environment.BASE_URL}/image-downloads?users_permissions_user=${userId}`, buildAxiosConfig(true));
  return result.data;
};

export const refundApi = async (paymentId, refundAmount, paymentType) => {
  switch (paymentType) {
    case 'album':
      const albumResult = await axios.post(`${environment.BASE_URL}/album-payments/refund/`, {id: paymentId, total: Number(refundAmount)}, buildAxiosConfig(true));
      return albumResult.data;
    case 'image':
      const imageResult = await axios.post(`${environment.BASE_URL}/image-payments/refund/`, {id: paymentId, total: Number(refundAmount)}, buildAxiosConfig(true));
      return imageResult.data;
    case 'season_pass':
      const spResult = await axios.post(`${environment.BASE_URL}/season-passes/refund/`, {id: paymentId, total: Number(refundAmount)}, buildAxiosConfig(true));
      return spResult.data;
    case 'weekend_pass':
      const wpResult = await axios.post(`${environment.BASE_URL}/weekend-passes/refund/`, {id: paymentId, total: Number(refundAmount)}, buildAxiosConfig(true));
      return wpResult.data;
    default:
      return {error: 'Invalid payment type'};
  };
};

export const addPhotoToAthleteApi = async (athleteId, imageId) => {
  const result = await axios.post(`${environment.BASE_URL}/user-athlete-manual-photos`, {user_athlete: athleteId, image: imageId}, buildAxiosConfig(true));
  return result.data;
};

export const adminMoveSeasonPassApi = async (seasonPassId, newAthleteId) => {
  const result = await axios.put(`${environment.BASE_URL}/season-passes/${seasonPassId}`, {user_athlete: newAthleteId}, buildAxiosConfig(true));
  return result.data;
};

export const adminModifyWeekendPassApi = async (weekendPassId, startDate, endDate, newAthleteId) => {
  const result = await axios.put(`${environment.BASE_URL}/weekend-passes/${weekendPassId}`, {starts_on: new Date(startDate), expires_on: new Date(endDate), user_athlete: newAthleteId}, buildAxiosConfig(true));
  return result.data;
};

export const hideAthleteResultApi = async (imageId, athleteId, userId, memo) => {
  const result = await axios.post(`${environment.BASE_URL}/hidden-athlete-photos`, {image: imageId, user_athlete: athleteId, users_permissions_user: userId, memo}, buildAxiosConfig(true));
  return result.data;
};

export const adminImageAddRequestApi = async (message) => {
  const result = await axios.post(`${environment.BASE_URL}/user-athletes/image-add-request`, {message}, buildAxiosConfig(true));
  return result.data;
};

export const toggleAthleteIgnoreMatchQuality = async (athlete) => {
  const result = await axios.put(`${environment.BASE_URL}/user-athletes/${athlete.id}`, {ignore_match_quality: !athlete.ignore_match_quality}, buildAxiosConfig(true));
  return result.data;
};
