export default function searchByPhotoReducer() {
  return [
    {
      id: 2,
      name: 'Athlete 1',
      image: 'https://t4.ftcdn.net/jpg/00/04/07/53/360_F_4075335_HJVXUNKQX2xPrAb5L0AZznVLbS5fX4ie.jpg',
      photos: [],
    },
    {
      id: 3,
      name: 'Athlete 2',
      image: 'https://media.gettyimages.com/photos/dallas-cowboys-cheerleaders-for-fans-during-the-official-opening-day-picture-id1330803248?s=612x612',
      photos: [],
    },
  ];
}
