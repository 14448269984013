import {createAction} from 'redux-actions';

import {
  SET_ERROR,
  CLOSE_ERROR,
} from '../lib/constants';

export const setError = createAction(SET_ERROR);
export const closeError = createAction(CLOSE_ERROR);


// export const updatePurchaseFlowIsFacial = async (dispatch, payload) => {
//   dispatch(setPurchaseFlowIsFacial(purchaseFlowIsFacial));
// };
