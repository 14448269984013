import {
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_TRY_LOGIN,
  SET_USER_TEAM_SUBSCRIPTIONS,
  SET_USER_DETAILS,
  SET_CART_MODAL_OPEN,
  SET_MENU_HEIGHT,
  SET_LAST_LOCATION,
  SET_OPEN_ORDERS,
  SET_SEASON_PASS_IMAGES,
} from '../lib/constants';

export const initialUserState = {
  user: null,
  jwtDetail: null,
  loggedIn: false,
  tryLogin: true,
  cartModalOpen: false,
  openOrders: [],
  seasonPassImages: [],
};

export function userReducer(state = initialUserState, action) {
  switch (action.type) {
    case SET_LOGGED_IN: {
      return {
        ...state,
        user: action.payload.user,
        jwtDetail: action.payload.jwtDetail,
        loggedIn: true,
        tryLogin: action.payload.tryLogin,
      };
    }
    case SET_LOGGED_OUT: {
      return {
        ...state,
        tryLogin: false,
        loggedIn: false,
      };
    }
    case SET_TRY_LOGIN: {
      return {
        ...state,
        tryLogin: action.payload,
      };
    }
    case SET_USER_DETAILS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case SET_CART_MODAL_OPEN: {
      return {
        ...state,
        cartModalOpen: action.payload,
      };
    }
    case SET_MENU_HEIGHT: {
      return {
        ...state,
        menuHeight: action.payload,
      };
    }
    case SET_LAST_LOCATION: {
      return {
        ...state,
        lastLocation: action.payload,
      };
    }
    case SET_OPEN_ORDERS: {
      return {
        ...state,
        openOrders: action.payload,
      };
    }
    case SET_SEASON_PASS_IMAGES: {
      return {
        ...state,
        seasonPassImages: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export function userTeamSubscriptionsReducer(state = initialUserState, action) {
  switch (action.type) {
    case SET_USER_TEAM_SUBSCRIPTIONS: {
      const newState = Object.assign({}, state);
      newState.user.team_subscriptions = action.payload.user;
      return newState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
