import React, {useState, ReactElement, CSSProperties} from 'react';

import {displayMoney} from '../../lib/functions';
import DeleteIcon from '@mui/icons-material/Delete';
import {removeCartItemApi} from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  Divider,
  Box,
  IconButton,
} from '@mui/material';
import {
  selectCartItems,
  selectCart,
} from '../../store/userSelectors';
import {doGetUserDetails} from '../user/teamSubscriptions';

const Cart = () => {
  const dispatch = useDispatch();
  const handleRemoveAlbumFromCart = (cartItemId) => {
    const removeAlbumFromCart = async () => {
      const response = await removeCartItemApi(cartItemId);
      doGetUserDetails(dispatch);
    };
    removeAlbumFromCart();
  };
  const cartItems = useSelector((state) => selectCartItems(state));
  const userCart = useSelector((state) => selectCart(state));
  return (
    <>
      {cartItems.map((cartItem) => {
        return (
          <>
            <Box sx={{flexGrow: 1}} style={{paddingBottom: 0}}>
              <Grid container >
                <Grid item xs={2}>
                  <IconButton style={{paddingTop: 16}} size="large" aria-label="add-payment-method-button" onClick={(_) => handleRemoveAlbumFromCart(cartItem.id)}>
                    <DeleteIcon fontSize="small" style={{color: 'white'}} />
                  </IconButton>
                  {/* <Button onClick={() => {handleRemoveAlbumFromCart(cartItem.id)}} >Remove Item</Button> */}
                </Grid>
                <Grid item xs={3}>
                  <p>{displayMoney(cartItem.price)}</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Digital Performance Album</p>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{flexGrow: 1}} style={{paddingTop: 0, paddingBottom: 0}}>
              <Grid container >
                <Grid item xs={0} lg={2} md={2} sm={2}>
                </Grid>
                <Grid item lg={10} xs={12}>
                  <p style={{paddingLeft: 16}}>Team: {`[${cartItem.album.performance_order}] ${cartItem.album.program_name} - ${cartItem.album.team_name} - ${cartItem.album.division_name}`}</p>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{flexGrow: 1}} style={{paddingTop: 0}}>
              <Grid container >
                <Grid item xs={0} lg={2} md={2} sm={2}>
                </Grid>
                <Grid item lg={10} xs={12}>
                  {cartItem.album.events ? <p style={{paddingLeft: 16}}>Event: {`${cartItem.album.events[0].start_date.substring(0, 4)} ${cartItem.album.events[0].brand_name} ${cartItem.album.events[0].name} [${cartItem.album.events[0].city}, ${cartItem.album.events[0].state}]`}</p> : <></>}
                </Grid>
              </Grid>
            </Box>
            <Divider></Divider>
          </>
        );
      })}
      <h2>Total: {displayMoney(userCart.total)}</h2>
    </>
  );
};

export default Cart;
