import {Action, createAction} from 'redux-actions';

import {
  SET_ALBUMS,
} from '../../lib/constants';

import {getUserAlbumsApi, incrementAlbumViewApi} from '../../api';
import {crisp} from '../../../src/index';

// Crisp.configure('b0fb5e74-a56f-401b-82a3-17f3aa6c9392');

const setAlbums = createAction(SET_ALBUMS);

export const getUserAlbums = async (dispatch, userId) => {
  try {
    const response = await getUserAlbumsApi();
    try {
      crisp.session.setData({user_albums: JSON.stringify(response.data)});
    } catch (err) {
      console.error(err);
    }
    dispatch(setAlbums({albums: response.data}));
  } catch (err) {
    dispatch(setAlbums({albums: []}));
  }
};

export const incrementAlbumView = async (dispatch, albumId) => {
  try {
    const response = await incrementAlbumViewApi(albumId);
    getUserAlbums(dispatch);
  } catch (err) {
    getUserAlbums(dispatch);
  }
};
