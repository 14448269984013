import React, {useState} from 'react';
// import {Blurhash} from 'react-blurhash';
import {
  LazyLoadImage,
} from 'react-lazy-load-image-component';
import styled from 'styled-components';

import Skeleton from '@mui/material/Skeleton';

const ImageWrapper = styled.div`
  position: relative;
`;

// const StyledBlurhash = styled(Blurhash)`
//   z-index: 20;
//   position: absolute !important;
//   top: 0;
//   left: 0;
//   right: 0;
//   margin: 0 auto;
// `;

const StyledSkeleton = styled(Skeleton)`
  z-index: 20;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #404040;
`;

interface IOptimizedImageProps {
  image: { url: string; blurhash: string, id: number, scrollPosition: any };
  style: any;
}

function OptimizedImage(props: IOptimizedImageProps) {
  const {image, style} = props;

  const [isLoaded, setLoaded] = useState(false);
  const [isLoadStarted, setLoadStarted] = useState(true);

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleLoadStarted = () => {
    // console.log('Started: ');
    setLoadStarted(true);
  };

  return (
    <ImageWrapper>
      <LazyLoadImage
        key={image.id}
        src={image.url}
        // height={500}
        // width={333}
        style={style}
        onLoad={handleLoad}
        beforeLoad={handleLoadStarted}
        scrollPosition={image.scrollPosition}
      />
      {!isLoaded && isLoadStarted && (
        // <StyledBlurhash
        //   hash={image.blurhash}
        //   width={'320px'}
        //   height={'213px'}
        //   resolutionX={32}
        //   resolutionY={32}
        //   punch={1}
        // />
        <StyledSkeleton
          variant="rectangular" width={style.width} height={style.height} animation={'wave'} />
      )}
    </ImageWrapper>
  );
}

export {OptimizedImage};
