import React, {useState, Suspense, useEffect} from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ReportIcon from '@mui/icons-material/Report';
const ReportPhotoModal = React.lazy(()=> import('../util/ReportPhotoModal'));

const ReportPhoto = (props) => {
  const {
    image,
    refresh,
    athleteView,
    athlete,
    user,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [leftPad, setLeftPad] = useState('42%');
  // useEffect(() => {
  //   if (image.width > image.height) {
  //     setLeftPad('25%');
  //   } else {
  //     setLeftPad('42%');
  //   }
  // }, [image]);
  return (
    <>
      <Stack direction="row" spacing={0}s>
        <Chip onClick={()=>{
          setIsOpen(true);
          // console.log(image);
        }} size="small" icon={<ReportIcon />} label="Report" variant="outlined" color="info" style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}/>
      </Stack>
      <Suspense fallback={<div>Loading Report Image Dialog...</div>}>
        <ReportPhotoModal isOpen={isOpen} setIsOpen={setIsOpen} image={image} refresh={refresh} athlete={athlete} athleteView={athleteView} user={user} />
      </Suspense>
    </>
  );
};

export default ReportPhoto;
