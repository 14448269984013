import React, {ReactElement, useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {useSelector} from 'react-redux';
import environment from './environment';

type LoadStripeProps = {
  children: React.ReactNode; // 👈️ added type for children
}

const stripePromise = loadStripe(environment.STRIPE_PK);
const LoadStripe = ({children}: LoadStripeProps): ReactElement => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default LoadStripe;
