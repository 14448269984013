export const selectCurrentLoginState = (state) =>
  state.userState?.loggedIn;

export const selectCurrentUserEmail = (state) =>
  state.userState?.user?.email ?? '';

export const selectCurrentUser = (state) =>
  state.userState?.user ?? {};

export const selectCurrentUserId = (state) =>
  state.userState?.user?.id ?? '';
export const selectCurrentUserName = (state) =>
  state.userState?.user?.username ?? '';
export const selectCurrentUserRole = (state) =>
  state.userState?.user?.role ?? {};

export const selectCurrentUserPhoneNumber = (state) =>
  state.userState?.user?.phone_number ?? '';

export const selectTeamSubscriptions = (state) =>
  state.userState?.user?.team_subscriptions || [];

export const selectCartItems = (state) =>
  state.userState?.user?.cart?.cart_items || [];

export const selectPaymentMethods = (state) =>
  state.userState?.user?.payment_sources || [];

export const selectCart = (state) =>
  state.userState?.user?.cart || {total: 0, sub_total: 0};

export const selectCartModalState = (state) =>
  state.userState?.cartModalOpen || false;

export const selectMenuHeight = (state) =>
  state.userState?.menuHeight || 0;
export const selectLastLocation = (state) =>
  state.userState?.lastLocation || '/';

export const selectopenOrders = (state) => state.userState.openOrders;
export const selectSeasonPassImages = (state) => state.userState.seasonPassImages;
