export const selectCurrentAlbumsState = (state) =>
  state?.userAlbumState.found ?? [];

export const selectCurrentAlbumsStateOwned = (state) =>
  state?.userAlbumState.owned ?? [];

export const selectCurrentImagesStateOwned = (state) =>
  state?.userAlbumState.owned_images ?? [];

export const selectCurrentUserAlbum = (state, userAlbumId) => {
  let foundUserAlbum;
  if (state.userAlbumState.found?.length) {
    foundUserAlbum = state.userAlbumState.found?.find((userAlbum) => userAlbum.id === +userAlbumId);
  }
  return foundUserAlbum;
};

export const selectUserAlbumByAlbumId = (state, albumId) => {
  let foundUserAlbum;
  if (state.userAlbumState.found?.length) {
    foundUserAlbum = state.userAlbumState.found?.find((userAlbum) => userAlbum.album.id === +albumId);
  }
  return foundUserAlbum;
};
