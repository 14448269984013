import {
  SET_ERROR,
  CLOSE_ERROR,
} from '../lib/constants';

export const initialErrorState = {
  modalOpen: false,
  statusCode: 0,
  message: '',
};

export function errorReducer(state = initialErrorState, action) {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...state,
        modalOpen: true,
        statusCode: action.payload.statusCode,
        message: action.payload.message,
      };
    }
    case CLOSE_ERROR: {
      return {
        ...state,
        modalOpen: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
