const devEnvironment = {
  BASE_URL: (process.env.REACT_APP_BASE_URL_DEV) ? process.env.REACT_APP_BASE_URL_DEV : 'http://127.0.0.1:1337',
  FRONTEND_URL: 'http://127.0.0.1:3000',
  STRIPE_PK: 'pk_test_4pwIPgvVM65phYyS3UdW4Mjf00vpmG86VF',
  STRIPE_SK: 'sk_test_SxQOtdiPAe6yYO5eKQ1sQQBZ00g2D9PcAv',
  MAX_DOWNLOADS: 15,
};

const prodEnvironment = {
  BASE_URL: (process.env.REACT_APP_BASE_URL_PROD) ? process.env.REACT_APP_BASE_URL_PROD :'https://api.rtevent.com',
  FRONTEND_URL: 'https://rtevent.com',
  STRIPE_PK: 'pk_live_T9NyRRdK8sSZZQjVSkBsVhZk00tF8cENe2', // TODO: Pull from env if exists
  STRIPE_SK: 'sk_test_SxQOtdiPAe6yYO5eKQ1sQQBZ00g2D9PcAv', // TODO: Pull from env if exists
  MAX_DOWNLOADS: 15,
};

const environment = process.env.NODE_ENV === 'production' ? prodEnvironment : devEnvironment;

export default environment;
