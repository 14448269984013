import {
  SET_ALBUMS,
} from '../../lib/constants';
export const initialAlbumState = [];

export function albumReducer(state = initialAlbumState, action) {
  switch (action.type) {
    case SET_ALBUMS: {
      const foundAlbums = action.payload.albums.found ?? [];
      const ownedAlbums = action.payload.albums.owned ?? [];
      const ownedImages = action.payload.albums.owned_images ?? [];
      return {
        found: [
          ...foundAlbums,
        ],
        owned: [
          ...ownedAlbums,
        ],
        owned_images: [
          ...ownedImages,
        ],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
