import './MuiClassNameSetup'; // This needs to be at top of index.js
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './App.css';
import {Provider} from 'react-redux';
import buildStore from './store/store';
import {darkV4} from './theme';
import LoadStripe from '../src/LoadStripe';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import {Crisp} from 'crisp-sdk-web';


export const crisp = Crisp;
crisp.configure('b0fb5e74-a56f-401b-82a3-17f3aa6c9392');
// Crisp will be displayed
import ButtonAppBar from './components/app-bar';
// const ButtonAppBar = React.lazy(()=> import('./components/app-bar'));

const Events = React.lazy(() => import('./views/events'));
const Albums = React.lazy(()=> import('./views/albums'));
const Album = React.lazy(()=> import('./views/gallery'));
const MyAthlete = React.lazy(()=> import('./views/MyAthlete/myAthlete'));
const addNewAthlete = React.lazy(()=> import('./views/MyAthlete/addAthlete'));
const UserAlbums = React.lazy(()=> import('./components/user/albums'));
const AdminAlbumContainer = React.lazy(()=> import('./components/admin/AdminAlbumContainer'));
const Report = React.lazy(()=> import('./components/admin/AdminReport'));
const MyAlbums = React.lazy(()=> import('./components/user/MyAlbums'));
const Login = React.lazy(()=> import('./views/user/login'));
const LoginSimple = React.lazy(()=> import('./views/user/LoginSimple'));
const RegisterContainer = React.lazy(()=> import('./views/user/RegisterContainer'));
const PasswordReset = React.lazy(()=> import('./views/user/PasswordReset'));
const PrivateRoute = React.lazy(()=> import('./components/util/PrivateRoute'));
const PublicActionRoute = React.lazy(()=> import('./components/util/PublicActionRoute'));
const AlbumContainer = React.lazy(()=> import('./components/user/AlbumContainer'));
const CheckoutContainer = React.lazy(()=> import('./components/user/CheckoutContainer'));
const UserProfilePage = React.lazy(()=> import('./components/user/UserProfilePage'));
const Payment = React.lazy(()=> import('./components/user/Payment'));
const ImagePayment = React.lazy(()=> import('./components/user/ImagePayment'));
const AddCreditCard = React.lazy(()=> import('./components/user/AddCreditCard'));
const OpenOrders = React.lazy(()=> import('./components/user/OpenOrders'));
const PurchaseAlbumContainer = React.lazy(()=> import('./components/user/PurchaseAlbumContainer'));
const AdminUsers = React.lazy(()=> import('./components/admin/AdminUsers'));
const AdminUser = React.lazy(()=> import('./components/admin/AdminUser'));
const AdminAddPhotoToAthleteRequest = React.lazy(()=> import('./components/admin/addPhotoToAthleteRequest'));
const AdminUserFoundAlbums = React.lazy(()=> import('./components/admin/AdminUserFoundAlbums'));
const AdminUserFacialRecognition = React.lazy(()=> import('./components/admin/AdminUserFacialRecognition'));
const AdminDefaultSettingsPage = React.lazy(()=> import('./components/admin/AdminDefaultSettingsPage'));
const Footer = React.lazy(()=> import('./components/Footer/footer'));
const Header = React.lazy(()=> import('./components/Header/header'));
const UnsubscribePage = React.lazy(()=> import('./components/user/UnsubscribePage'));
const AdminPage = React.lazy(()=> import('./components/admin/AdminPage'));
const AdminEditUserNotificationSettings = React.lazy(()=> import('./components/admin/AdminEditUserNotificationSettings'));
const SearchImages = React.lazy(()=> import('./components/user/SearchImages'));
const AdminPhotographerReport = React.lazy(()=> import('./components/admin/AdminPhotographerReport'));
const AdminReportPhotoReview = React.lazy(()=> import('./components/admin/AdminReportPhotoReview'));
const SelfieInstructions = React.lazy(()=> import('./views/MyAthlete/SelfieInstructions'));

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Replay} from '@sentry/replay';

if (process.env.NODE_ENV === 'development') {
  // Development Config
  Sentry.init({
    dsn: 'https://901b796554054604a6903aa0ff607de0@o4504300865191936.ingest.sentry.io/4504300867551232',
    integrations: [
      new BrowserTracing(),
      new Replay({
        // Additional SDK configuration goes in here, for example:
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    environment: 'development',
    // debug: true,
    attachStacktrace: true,
    autoSessionTracking: true,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  // Production Config
  Sentry.init({
    dsn: 'https://901b796554054604a6903aa0ff607de0@o4504300865191936.ingest.sentry.io/4504300867551232',
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    environment: 'production',
    attachStacktrace: true,
  });
}
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
export const store = buildStore(sentryReduxEnhancer);


// Include the Crisp code here, without the <script></script> tags
// window.$crisp = [];
// window.CRISP_WEBSITE_ID = 'b0fb5e74-a56f-401b-82a3-17f3aa6c9392';

// (function() {
//   const d = document;
//   const s = d.createElement('script');

//   s.src = 'https://client.crisp.chat/l.js';
//   s.async = 1;
//   d.getElementsByTagName('head')[0].appendChild(s);
// })();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={darkV4}>
            <LoadStripe>
              <CssBaseline />
              <div className='MainApp'>
                <div className="App">
                  <Router>
                    <ButtonAppBar />
                    <Suspense fallback={<div>Loading...</div>}>
                      <Header />
                      <Switch>
                        <PublicActionRoute path="/view-album/:albumId" exact component={AlbumContainer} />
                        <PublicActionRoute path="/unsubscribe" exact component={UnsubscribePage} />
                        <PrivateRoute path="/" exact component={SearchImages} />
                        <PrivateRoute path="/payment" exact component={Payment} />
                        <PrivateRoute path="/image-payment" exact component={ImagePayment} />
                        <PrivateRoute path="/events" exact component={Events} />
                        <PrivateRoute path="/events/:eventid" exact component={Albums} />
                        <PrivateRoute path="/events/:eventid/album/:albumid" exact component={Album} />
                        <PrivateRoute path="/user-album/:albumId" exact component={AlbumContainer} />
                        <PrivateRoute path="/checkout/:orderId" exact component={CheckoutContainer} />
                        <PrivateRoute path="/purchaseAlbum/:albumUuid" exact component={PurchaseAlbumContainer} />
                        <PrivateRoute path="/user-profile" exact component={UserProfilePage} />
                        <PrivateRoute path="/admin/albums" exact component={AdminAlbumContainer} />
                        <PrivateRoute path="/admin/reports" exact component={Report} />
                        <PrivateRoute path="/admin/report-photo-review" exact component={AdminReportPhotoReview} />
                        <PrivateRoute path="/admin/users" exact component={AdminUsers} />
                        <PrivateRoute path="/admin/users/notification-settings" exact component={AdminEditUserNotificationSettings} />
                        <PrivateRoute path="/admin/users/found-albums/:userid" exact component={AdminUserFoundAlbums} />
                        <PrivateRoute path="/admin/users/user-athletes/:userid" exact component={AdminUserFacialRecognition} />
                        <PrivateRoute path="/admin/users/:userid" exact component={AdminUser} />
                        <PrivateRoute path="/admin/settings" exact component={AdminDefaultSettingsPage} />
                        <PrivateRoute path="/admin/photographer-report" exact component={AdminPhotographerReport} />
                        <PrivateRoute path="/admin/add-photo-to-athlete-request" exact component={AdminAddPhotoToAthleteRequest} />
                        <PrivateRoute path="/admin" exact component={AdminPage} />
                        <PrivateRoute path="/AddPaymentMethod" exact component={AddCreditCard} />
                        <PrivateRoute path="/MyAlbums" exact component={MyAlbums} />
                        <PrivateRoute path="/OpenOrders" exact component={OpenOrders} />
                        <PrivateRoute path="/MyAthlete" exact component={MyAthlete} />
                        <PrivateRoute path="/addNewAthlete" exact component={addNewAthlete} />
                        <PrivateRoute path="/search-albums" exact component={UserAlbums} />
                        <PrivateRoute path="/SelfieInstructions" exact component={SelfieInstructions} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/verified" exact render={() => <Login verified={true} />} />
                        <Route path="/LoginSimple" exact component={LoginSimple} />
                        <Route path="/LoginSimple/?loginToken=:code" exact component={LoginSimple} />
                        <Route path="/register" exact component={RegisterContainer} />
                        <Route path="/PasswordReset" exact component={PasswordReset} />
                        <Route path="/PasswordReset/?code=:code" exact component={PasswordReset} />
                        <Redirect from="*" to="/" />
                      </Switch>
                    </Suspense>
                  </Router>
                </div>
                <Footer />
              </div>
            </LoadStripe>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
