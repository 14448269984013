import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import TuneIcon from '@mui/icons-material/Tune';
import Menu from '@mui/material/Menu';
import {AccountCircle, MoreVert as MoreIcon, Photo as PhotoIcon, Logout as LogoutIcon, LockOpen as LockOpenIcon, Assessment as AssessmentIcon, Face as FaceIcon} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {
  selectCart,
  selectCartItems,
  selectCartModalState,
  selectCurrentLoginState,
  selectCurrentUserRole,
} from '../store/userSelectors';
import {logoutUser, setCartModalOpen, setMenuHeight, getUserDetails, getSeasonPassImages} from '../store/userActions';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material';
import {selectCurrentAlbumsStateOwned, selectCurrentImagesStateOwned} from '../store/albums/albumSelector';
import Cart from '../components/user/cart';
import {convertCartToOrder} from '../api';
import {Alert} from '@mui/material';
import {useEffect} from 'react';
import {doSetAlbums} from './user/albums';
import {checkRolePermission} from '../lib/functions';

const handleLogout = (dispatch) => {
  return () => {
    const doLogout = logoutUser();
    doLogout(dispatch);
  };
};


export default function ButtonAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state) => selectCurrentLoginState(state));
  const userRole = useSelector((state) => selectCurrentUserRole(state));
  const cartItems = useSelector((state) => selectCartItems(state));
  const userOwnedAlbums = useSelector((state) => selectCurrentAlbumsStateOwned(state));
  const userOwnedImages = useSelector((state) => selectCurrentImagesStateOwned(state));
  const userCart = useSelector((state) => selectCart(state));
  const isCartModalOpen = useSelector((state) => selectCartModalState(state));
  const [cartIsEmptyError, setCartIsEmptyError] = React.useState(false);

  const headerInfo = React.useRef(null);

  useEffect(() => {
    if (loggedIn) {
      doSetAlbums(dispatch);
      getSeasonPassImages(dispatch);
    }
  }, [dispatch, loggedIn]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickOpenCart = () => {
    dispatch(setCartModalOpen(true));
  };

  useEffect(() => {
    if (headerInfo) {
      dispatch(setMenuHeight(headerInfo.current.clientHeight));
    }
  }, [dispatch, headerInfo]);

  const handleCloseCart = () => {
    setCartIsEmptyError(false);
    dispatch(setCartModalOpen(false));
  };

  const handleCheckout = async () => {
    if (cartItems.length === 0) {
      setCartIsEmptyError(true);
    } else {
      handleCloseCart();
      const res = await convertCartToOrder(userCart.id);
      await getUserDetails(dispatch);
      if (res.status !== 200) {
        throw (new Error('Error converting cart to order'));
      } else {
        history.push('/checkout/' + res.data.key);
      }
    }
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    const {current: descriptionElement} = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      [
      <MenuItem id="profile" onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem id="my-account" onClick={handleMenuClose}>My account</MenuItem>
      ]
    </Menu>
  );

  const mobileMenuId = 'mobile-menu-id';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        id="logout"
        onClick={(_) => {
          const logout = handleLogout(dispatch);
          logout();
          history.push('/Login');
        }}>
        <IconButton size="large" aria-label="shopping cart" aria-haspopup="true" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
      {checkRolePermission(userRole.type, 'search_results') ?
        <MenuItem
          id="search_results"
          onClick={(_) => {
            history.push('/');
            handleMobileMenuClose();
          }}>
          <IconButton
            size="large"
            aria-label="current user available albums"
            aria-controls="primary-user-album-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <PhotoIcon />
          </IconButton>
          <p>Search</p>
        </MenuItem> :
      []}
      {/* {checkRolePermission(userRole.type, 'my_athlete') ?
        <MenuItem
          onClick={(_) => {
            history.push('/MyAthlete');
            handleMobileMenuClose();
          }}
        >
          <IconButton
            size="large"
            aria-label="My Athletes tab"
            aria-haspopup="true"
            color="inherit"
          >
            <FaceIcon />
          </IconButton>
          <p>My Athletes</p>
        </MenuItem> :
      []} */}
      {checkRolePermission(userRole.type, 'purchases') ?
        <MenuItem
          id="purchases"
          onClick={(_) => {
            history.push('/MyAlbums');
            handleMobileMenuClose();
          }}>
          <IconButton
            size="large"
            aria-label="current user available albums"
            aria-controls="primary-user-album-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Badge badgeContent={userOwnedAlbums.length + userOwnedImages.length} color="error">
              <PhotoIcon />
            </Badge>
          </IconButton>
          <p>Purchases</p>
        </MenuItem> :
      []}
      {checkRolePermission(userRole.type, 'profile') ?
        <MenuItem
          id="profile"
          onClick={(_) => {
            history.push('/user-profile');
            handleMobileMenuClose();
          }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem> :
      []}
      {checkRolePermission(userRole.type, 'admin') ?
        <MenuItem
          key="admin"
          onClick={(_) => {
            history.push('/admin');
            handleMobileMenuClose();
          }}
        >
          <IconButton size="large"
            aria-label="admin-page"
            aria-haspopup="true"
            color="inherit"
          >
            <AssessmentIcon />
          </IconButton>
          <p>Admin</p>
        </MenuItem> :
      []}
    </Menu>
  );


  return (
    <>
      <Box ref={headerInfo} sx={{flexGrow: 1}}>
        <AppBar position="static" enableColorOnDark color='secondary'>
          <Toolbar>
            <Link to="/">
              <img alt="logo" src="/logo.png" style={{maxWidth: 50, marginRight: 10}} />
            </Link>
            <IconButton
              variant="h6"
              nowrap="true"
              component={Link}
              to="/"
              sx={{display: {xs: 'block', sm: 'block'}}}
              style={{color: 'white'}}
              size="large">
              Photos
            </IconButton>
            <Box sx={{flexGrow: 1}} />
            {loggedIn ? <>
              {checkRolePermission(userRole.type, 'search_results') ?
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                  <IconButton size="small"
                    aria-label="albums"
                    aria-haspopup="true"
                    color="inherit" onClick={() => {
                      history.push('/');
                    }}>
                    <PhotoIcon />
                    <p>&nbsp;Search</p>
                  </IconButton>
                </Box> :
              <></>}
              {/* {checkRolePermission(userRole.type, 'my_athlete') ?
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                  <IconButton
                    size="small"
                    aria-label="my-athletes"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                      history.push('/MyAthlete');
                    }}
                  >
                    <FaceIcon />
                    <p>My Athletes</p>
                  </IconButton>
                </Box> :
              <></>} */}
              {checkRolePermission(userRole.type, 'purchases') ?
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                  <IconButton size="small"
                    aria-label="albums"
                    aria-haspopup="true"
                    color="inherit" onClick={() => {
                      history.push('/MyAlbums');
                    }}>
                    <Badge badgeContent={userOwnedAlbums.length + userOwnedImages.length} color="error">
                      <PhotoIcon />
                    </Badge>
                    <p>&nbsp;Purchases</p>
                  </IconButton>
                </Box> :
              <></>}
              {checkRolePermission(userRole.type, 'profile') ?
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(_) => {
                      history.push('/user-profile');
                    }}
                  >
                    <AccountCircle />
                    <p>Profile</p>
                  </IconButton>
                </Box> :
              <></>}
              {checkRolePermission(userRole.type, 'admin') ?
                  <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    <IconButton size="small"
                      aria-label="admin page"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={(_) => {
                        history.push('/admin');
                      }}>
                      <TuneIcon />
                      <p>Admin</p>
                    </IconButton>
                  </Box> :
              <></>}
              <Box sx={{display: {xs: 'none', md: 'block'}}}>
                <IconButton size="small"
                  aria-label="shopping cart"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={(_) => {
                    const logout = handleLogout(dispatch);
                    logout();
                    history.push('/Login');
                  }}>
                  <LogoutIcon />
                  <p>Logout</p>
                </IconButton>
              </Box>
              <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </> :
              <Box sx={{display: {xs: 'block', md: 'block'}}}>
                <IconButton size="small"
                  aria-label="login"
                  // aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  // onClick={handleMobileMenuOpen}
                  color="inherit" component={Link} to="/login">
                  <LockOpenIcon />
                  <p>Login</p>
                </IconButton>
              </Box>}
          </Toolbar>
        </AppBar>
        {loggedIn ? renderMobileMenu : <></>}
        {renderMenu}
      </Box>
      <Dialog
        fullWidth
        maxWidth='md'
        open={isCartModalOpen}
        onClose={handleCloseCart}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableScrollLock={true}
      >
        <Grid container>
          <Grid item xs={7}>
            <DialogTitle id="scroll-dialog-title">Your Shopping Cart</DialogTitle>
          </Grid>
          {cartIsEmptyError ?
            <Grid item xs={5}>
              <Alert variant="outlined" severity="info" style={{color: 'lightblue', marginTop: 5}}>Your cart is empty!</Alert>
            </Grid> :
            []}
        </Grid>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Cart />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart}>Close</Button>
          <Button onClick={handleCheckout}>Checkout</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
