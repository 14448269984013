
import {Gallery, Item} from 'react-photoswipe-gallery';

import React, {useState, useEffect, useMemo, Suspense} from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import {format} from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import {Link} from 'react-router-dom';
import Fab from '@mui/material/Fab';
import {CircularProgress} from '@mui/material';
import {Alert} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {selectCartItems} from '../../store/userSelectors';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useHistory} from 'react-router';
import {getImage} from '../../api';
import {setPaymentDetails} from '../../store/purchase/purchaseAction';
import {getSeasonPassImages} from '../../store/userActions';
import {displayMoney} from '../../lib/functions';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {trackWindowScroll} from 'react-lazy-load-image-component';
import {OptimizedImage} from '../util/OptimizedImage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DownloadIcon from '@mui/icons-material/Download';
import {useTheme} from '@mui/material/styles';
// import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
// import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';
import {selectSeasonPassImages} from '../../store/userSelectors';

import ReportPhoto from '../util/ReportPhoto';
const smallItemStyles = {
  cursor: 'pointer',
  objectFit: 'contain',
  width: '300px',
  height: '200px',
};

const Album = ({inAlbum, inImages, inEvent, inUserAlbum, isLoading, inOwned, inBoughtImages, refresh, seasonPassImages}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const cartItems = useSelector((state) => selectCartItems(state));

  const [album, setAlbum] = useState(inAlbum);
  const [images, setImages] = useState(inImages);
  const [event, setEvent] = useState(inEvent);
  const [owned, setOwned] = useState(inOwned);
  const [userAlbum, setUserAlbum] = useState(inUserAlbum);
  const [boughtImages, setBoughtImages] = useState(inBoughtImages);
  const [payAmount, setPayAmount] = useState(0);
  const [lazyLoad, setLazyLoad] = useState(true);
  const [alreadyInCartError, setAlreadyInCartError] = useState(false);
  const history = useHistory();
  const sharePrice = useMemo(() => {
    const shPrice = userAlbum?.album?.events?.[0]?.event_pricings?.[0]?.share_price * 1;
    return shPrice;
  }, [userAlbum]);

  // const remainingShares = useMemo(() => userAlbum?.album?.album_payments?.reduce((current, payment) => {
  //   const shares = Math.round(payment.total / sharePrice)
  //   console.log(shares)
  //   return Math.max(current - shares, 0)
  // }, requiredSharesToReleaseOrder), [album, userAlbum])
  const remainingShares = 7;

  const albumPrice = useMemo(()=>{
    // if ((remainingShares * sharePrice) === 0){
    //   return sharePrice
    // } else {
    //   return remainingShares * sharePrice
    // }
    return remainingShares * sharePrice;
  }, [remainingShares, sharePrice]);

  const setPayDetails = (imageId, payAmount, inImage) => {
    dispatch(setPaymentDetails({imageId, payAmount, image: inImage, paymentType: 'image'}));
  };
  const TheGallery = ({scrollPosition})=>(
    <Gallery shareButton={false}>
      <Grid container spacing={1} item xs={11}>
        {images.map((image, i) => (
          <Grid item key={i} xs={12} sm={12} md={6} lg={4} xl={3}
            paddingBottom={0}
            minHeight={200}
            style={{
              display: 'flex', // Set display to flex to control child alignment
              flexDirection: 'column', // Stack children vertically
              justifyContent: 'flex-end', // Align children to the end to reduce space
              backgroundColor: i % 2 === 0 ? '#2c2c2c' : '#262626',
              border: '1px solid #3d3d3d',
            }}>
            <div style={{
              position: 'relative', // Relative position for absolute positioning of children
              padding: 0, // Remove padding
              marginBottom: 0, // Remove bottom margin
            }}>
              <Item
                original={image.url}
                thumbnail={image.url}
                width={image?.width ?? 3840}
                height={image?.height ?? 2160}
                title={`${image.file_name} (${i + 1}/${images.length}) REF#${image.id}`}
              >
                {({ref, open}) => (
                  <div ref={ref} onClick={() => {
                  // console.log(ref);
                  // setLazyLoad(false);
                    open();
                  }}>
                    <OptimizedImage key={i} image={{url: image.url, id: image.id}} scrollPosition={scrollPosition} style={smallItemStyles} />
                  </div>
                )}
              </Item>
              <div style={{
                position: 'absolute',
                bottom: '5px', // Adjust as needed
                left: '50%', // Center horizontally
                transform: 'translateX(-50%)', // Adjust for the centering
              }}>
                <Suspense fallback={<div>Loading Report Photo Icon...</div>}>
                  <ReportPhoto image={{...image, ...{event, album}}} refresh={refresh} />
                </Suspense>
              </div>
            </div>
            <div style={{width: '100%'}}> {/* No padding or margin to reduce space */}
              { (owned || image.owned || seasonPassImages.includes(image.id)) && (image.full_image_available || image.is_hevc) ?
            <Button variant="contained"
              color="primary"
              startIcon={<DownloadIcon />} onClick={async () => {
                try {
                  window.open(getImage(image.id, image.file_name), '_blank');
                } catch (err) {
                  alert('Image Could Not Be Downloaded');
                }
              }}
              sx={{
                margin: theme.spacing(1),
                textTransform: 'none',
              }}>
              <Typography>Download</Typography>
            </Button> : !image.full_image_available && (owned || image.owned) ? <Button variant="outlined" disabled>Not Ready For Download</Button> :
            <Button variant="contained"
              color="secondary"
              startIcon={<ShoppingCartIcon />}
              endIcon={<ArrowUpwardIcon />} onClick={async ()=> {
                console.log(album);
                setPayDetails(image.id, album.events[0].individual_image_price, image);
                history.push('/image-payment');
              }}
              sx={{
                margin: theme.spacing(1),
                textTransform: 'none',
              }}><Typography>Digital Download Instant Checkout {displayMoney(album.events[0].individual_image_price)}</Typography></Button>}
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant="caption">{image.file_name}</Typography>
                <Typography variant="caption">
    REF#{image.id}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

    </Gallery>
  );
  const LazyGallery = trackWindowScroll(TheGallery);

  useEffect(() => {
    setEvent(inEvent);
    setAlbum(inAlbum);
    setImages(inImages);
    setOwned(inOwned);
    setBoughtImages(inBoughtImages);
    setUserAlbum(inUserAlbum);
  }, [inEvent, inAlbum, inImages, inOwned, inBoughtImages, inUserAlbum]);

  const enoughImages = useMemo(() => {
    return images?.length >= 50;
  }, [images]);

  const updateImageSize = function(target, index) {
    // 1. Make a shallow copy of the items
    const items = [...images];
    // 2. Make a shallow copy of the item you want to mutate
    const item = {...items[index]};
    // 3. Replace the property you're intested in
    if (target.naturalWidth < 3840) {
      const scaleFactor = 3840 / target.naturalWidth;
      item.width = target.naturalWidth * scaleFactor;
      item.height = target.naturalHeight * scaleFactor;
    } else {
      item.width = target.naturalWidth;
      item.height = target.naturalHeight;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setImages(items);
  };
  if ((userAlbum?.user_album_views.length > 10 && !owned && !userAlbum?.bought_images) || (userAlbum?.bought_images && userAlbum?.user_album_views.length > 50)) {
    return (
      <Grid container item xs={12}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        key={event.id}>
        <Alert variant="outlined" severity="info">Album views exceeded. In an effort to protect athletes, we limit the number of times you are able to view an album before purchase ({userAlbum?.bought_images ? 50 : 10}).</Alert>
        <Typography variant="h5" component="h1" style={{paddingTop: 50}} key={`album-${album.id}}`}>
          Current Album: {album.performance_order} - {album.program_name} {album.team_name} - {album.division_name}
        </Typography>
        <Typography sx={{mb: 0}} color="textSecondary">
          Current Event: {event?.brand_name} - {event?.name}
        </Typography>
        <Typography sx={{mb: 0}} color="textSecondary">
          {event?.venue_name}
        </Typography>
        <Typography sx={{mb: 0}} color="textSecondary">
          ({event?.city}, {event?.state})
        </Typography>
        <Typography sx={{mb: 0.5}} color="textSecondary">
        Album Date: {format(new Date(album?.created_at ?? new Date()), 'yyyy-MM-dd hh:mm a')}
        </Typography>
        <Typography variant="caption" sx={{alignSelf: 'center'}}>
      REF#{album?.id ?? 0}
        </Typography>
        {!owned ?
                <Button size="large" onClick={() => {
                  history.push('/purchaseAlbum/' + album?.uuid);
                }}>
                  <AddIcon />
                  <Typography>{displayMoney(albumPrice)} Purchase Album&nbsp;</Typography>
                </Button> : <></>
        }

        <Button component={Link} to={`/`}>Change Album</Button>
        {alreadyInCartError ?
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlreadyInCartError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >Album is already in cart.</Alert> :
          <></>}
      </Grid>
    );
  } else {
    return (
      <Container maxWidth="lg" style={{maxWidth: 'none'}}>
        {isLoading ?
          <Box pt={8} pb={8}>
            <CircularProgress size={200} thickness={0.5} />
            <Box pt={4}>
              <Typography variant="h4" color="textSecondary">
                LOADING ALBUM...
              </Typography>
            </Box>
          </Box> :
          <Grid container item xs={12}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            key={event.id}
          >
            {!owned ?
            <Box sx={{'& > :not(style)': {m: 1}}}>
              <Fab disabled={!enoughImages} variant="extended" onClick={() => {
                history.push('/purchaseAlbum/' + album?.uuid);
              }} style={{
                margin: '0px',
                top: 'auto',
                right: '20px',
                bottom: '100px',
                left: 'auto',
                position: 'fixed',
                backgroundColor: 'black',
                color: 'white',
                // zIndex: 99998,
                borderColor: 'white',
                border: '2px solid',
              }}>
                { enoughImages ? `${displayMoney(albumPrice)} Purchase Album` : 'Insufficient Images' }
              </Fab>
            </Box> : <></>
            }

            <Typography variant="h5" component="h1" style={{paddingTop: 50}} key={`album-${album.id}}`}>
              Current Album: {album.performance_order} - {album.program_name} {album.team_name} - {album.division_name}
            </Typography>
            <Typography sx={{mb: 0}} color="textSecondary">
              Current Event: {event?.brand_name} - {event?.name}
            </Typography>
            <Typography sx={{mb: 0}} color="textSecondary">
              {event?.venue_name}
            </Typography>
            <Typography sx={{mb: 0}} color="textSecondary">
              ({event?.city}, {event?.state})
            </Typography>
            <Typography sx={{mb: 0.5}} color="textSecondary">
            Album Date: {format(new Date(album?.created_at ?? new Date()), 'yyyy-MM-dd hh:mm a')}
            </Typography>
            <Typography variant="caption" sx={{alignSelf: 'center'}}>
      REF#{album?.id ?? 0}
            </Typography>
            <Button component={Link} to={`/`}>Change Album</Button>
            {!owned ?
            <Button disabled={!enoughImages} size="large" onClick={() => {
              history.push('/purchaseAlbum/' + album?.uuid);
            }}>
              <AddIcon />
              <Typography>{ enoughImages ? `${displayMoney(albumPrice)} Purchase Album` : 'Insufficient Images' }&nbsp;</Typography>
            </Button> : <></>
            }
            { !enoughImages ?
            <Typography variant="h5" component="h1">
              It appears as though these photographs are still on the long journey to our headquarters as we are having trouble with the internet connection at the venue. Delays up to several hours may be expected. Please continue to check back periodically.
            </Typography> : <></>}
            {alreadyInCartError ?
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlreadyInCartError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >Album is already in cart.</Alert> :
              <></>}
            <LazyGallery></LazyGallery>
          </Grid>
        }
      </Container>

    );
  }
};

export default React.memo(Album);
