import {createAction} from 'redux-actions';

import {
  SET_PAYMENT_DETAILS,
  SET_PURCHASE_FLOW_IS_FACIAL,
} from '../../lib/constants';

const setPurchaseFlowIsFacial = createAction(SET_PURCHASE_FLOW_IS_FACIAL);
export const setPaymentDetails = createAction(SET_PAYMENT_DETAILS);

/**
 * updatePurchaseFlowIsFacial
 *
 * @param {Dispatch<AnyAction>} dispatch
 * @param {boolean} purchaseFlowIsFacial
 */
export const updatePurchaseFlowIsFacial = async (dispatch, purchaseFlowIsFacial) => {
  dispatch(setPurchaseFlowIsFacial(purchaseFlowIsFacial));
};
