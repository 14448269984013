/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux';

/*  Thunk
Redux Thunk middleware allows you to write action creators that return a function instead of an
action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain
condition is met. The inner function receives the store methods dispatch and getState as parameters.
*/

import thunk from 'redux-thunk';

// Import reducers and state type
import {userReducer} from './userReducer';
import {albumReducer} from './albums/albumReducer';
import {publicActionRouteReducer} from './publicActionRoute/publicActionRouteReducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import searchByPhotoReducer from '../store/myAthlete/searchByPhotoReducer';
import activeAthlete from '../store/myAthlete/activeAthlete';
import {purchaseReducer} from './purchase/purchaseReducer';
import {errorReducer} from './errorReducer';


// Create the root reducer
const rootReducer = combineReducers({
  activeAthlete: activeAthlete,
  userState: userReducer,
  userAlbumState: albumReducer,
  athletes: searchByPhotoReducer,
  publicActionRouteState: publicActionRouteReducer,
  purchaseState: purchaseReducer,
  errorState: errorReducer,
});

// const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 100});

// let appliedMiddleWare = applyMiddleware(thunk);

// if (process.env.NODE_ENV === 'development') {
//   appliedMiddleWare = composeEnhancers(
//       applyMiddleware(thunk),
//   );
// }


// Create a configure store function of type `IAppState`
// export default function configureStore(startingState) {
//   const store = createStore(rootReducer, startingState || {}, appliedMiddleWare);
//   return store;
// }

export default function buildStore(sentryReduxEnhancer) {
  // appliedMiddleWare = composeEnhancers(
  //     applyMiddleware(thunk),
  //     sentryReduxEnhancer,
  // );
  const store = createStore( rootReducer,
      compose(applyMiddleware(thunk), sentryReduxEnhancer));
  return store;
}
