import {
  SET_CURRENT_PUBLIC_ACTION_ROUTE,
} from '../../lib/constants';
export const initialPublicActionRouteState = {
  currentPublicActionRoute: {
    id: 0,
    public_action_uuid: '',
    created_at: '',
    updated_at: '',
    number_of_times_accessed: 0,
    action_route: '',
    action_route_match_type: '',
    max_number_of_times_accessed: null,
    userId: 0,
  },
};

export function publicActionRouteReducer(state = initialPublicActionRouteState, action) {
  switch (action.type) {
    case SET_CURRENT_PUBLIC_ACTION_ROUTE: {
      return {
        ...state,
        currentPublicActionRoute: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
